import React from 'react';
import { StyleSheet, View, Text } from "react-native";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NavigationButton from '../../../components/NavigationButton';
import SpacingView from '../../../components/SpacingView';
import GlobalStyles from '../../../styles/GlobalStyles';

import SocialExtensionExtend from './SocialExtensionSendExtend'
import SocialExtensionSendMessagePixel from './SocialExtensionSendMessagePixel'
import SocialExtensionSendBlockToken from './SocialExtensionSendBlockToken'
import SocialExtensionMessages from './SocialExtensionMessages'

function SocialExtension({ provider, useGlobalState}) {
  return (
    <View style={styles.content} >
      <Text style={GlobalStyles.title}>Social Extension</Text>
      <Text style={GlobalStyles.description}>Our social extension is one of the most innovative extensions we have created yet. Now our pixels can to send each other messages, read their own messages and keep the messages visible on their pixels. What’s even better is that all the messages are on-chain. Can we say this is the first on-chain social messaging in NFTs? We believe so.</Text>
      <SpacingView />
      <SpacingView />
      <SpacingView />
      <View style={[styles.container, {
        flexDirection: "row",
        flexWrap: "wrap",
      }]}>
        <NavigationButton to="/the-pixels-inc-extensions/social/extend" title="extend" />
        <NavigationButton to="/the-pixels-inc-extensions/social/send-message" title="send message" />
        <NavigationButton to="/the-pixels-inc-extensions/social/messages" title="messages" />
        <NavigationButton to="/the-pixels-inc-extensions/social/blocked-pixels" title="blocked pixels" />
      </View>
      <SpacingView />
      <SpacingView />
      <Switch>
        <Route path="/the-pixels-inc-extensions/social/extend"><SocialExtensionExtend provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/social/send-message"><SocialExtensionSendMessagePixel provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/social/messages"><SocialExtensionMessages provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/social/blocked-pixels"><SocialExtensionSendBlockToken provider={provider} useGlobalState={useGlobalState}/></Route>
      </Switch>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 1000
  },
  bar: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default SocialExtension;
