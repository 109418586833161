import React, { useState, useEffect } from 'react';
import { TextInput, Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import ProviderAwareView from '../../../components/ProviderAwareView';
import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import StakeTokenButton from '../../../components/StakeTokenButton';
import SocialExtensionTargetToken from './SocialExtensionTargetToken';
import GlobalStyles from '../../../styles/GlobalStyles';
import SocialExtensionBlockedToken from './SocialExtensionBlockedToken'

const extensionId = 2

async function blockToken(provider, senderTokenIds, targetTokenIds, isBlockeds, onStarted, onError, onFinished) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        const collectionIds = senderTokenIds.map((v) => { return 0 })

        return Contract.methods.updateTokenBlockStatus(senderTokenIds, targetTokenIds, collectionIds, isBlockeds).send({
            from: selectedAccount,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error, receipt) => {
                onError()
            })
            .on('transactionHash', () => {
                onStarted()
            })
            .then((data) => {
                onFinished()
            })
    } else {
        onError("Please connect your wallet first!")
    }
}

async function getAllVariants(provider, orders) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        return Contract.methods.variantsOfExtension(1, [1, 2, 3, 4, 5, 6]).call()
    }
}

async function getTokens(provider) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.tokensOfOwner(selectedAccount).call()
    }
}

async function getVariantIdsOfTokens(provider, tokenIds) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.currentVariantIdsOf(2, tokenIds).call()
    }
}

async function getExtension(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        if (selectedAccount) {
            return Contract.methods.extensions(extensionId).call()
        }
    }
}

function SocialExtensionSendBlockToken({ provider, useGlobalState }) {
    const [addedMessages, setAddedMessages] = useState([]);
    const [targetTokenIdDebounce, setTargetTokenIdDebounce] = useState();
    const [targetTokenId, setTargetTokenId] = useState();
    const [targetTokenIdText, setTargetTokenIdText] = useState();
    const [isEnabled, setIsEnabled] = useState(null)
    const [extendedTokenIndexes, setExtendedTokenIndexes] = useState();
    const [selectedExtendedTokenIndex, setSelectedExtendedTokenIndex] = useState();
    const [allVariants, setAllVariants] = useState([])
    const [walletState, setWalletState] = useGlobalState("walletState")
    const [isTokenBlockEnabled, setIsTokenBlockEnabled] = useState(false);
    const [blockedTokens, setBlockedTokens] = useState(null);
    const [changedBlockedTokens, setChangedBlockedTokens] = useState(null);
    const [originalBlockedTokens, setOriginalBlockedTokens] = useState(null);
    const [warning, setWarning] = useState(null);
    const [changeWarning, setChangeWarning] = useState(null);
    
    useEffect(() => {
        refresh(true)
    }, [provider])

    useEffect(() => {
        reset()
    }, [selectedExtendedTokenIndex])

    const reset = () => {
        setWarning()
        setChangeWarning()
        setTargetTokenId()
        setBlockedTokens()
        setOriginalBlockedTokens()
        setChangedBlockedTokens()
        setTargetTokenIdText("")
        getAllBlockedTokens()
    }

    const getAllBlockedTokens = () => {
        if (selectedExtendedTokenIndex) {
            fetch('https://api.int.art/collections/the-pixels-inc/extensions/blockedTokens/' + selectedExtendedTokenIndex)
            .then(response => response.json())
            .then(data => {
                setBlockedTokens(data.blockedTokens)
                setOriginalBlockedTokens(JSON.parse(JSON.stringify(data.blockedTokens)))
            })
        }
    }

    const refresh = async (refreshSelectedTokenIds) => {
        if (!provider) {
            return
        }

        setWarning()
        setChangeWarning()
        
        let _extension = await getExtension(provider)

        if (_extension) {
            setIsEnabled(_extension.isEnabled);
            if (_extension.isEnabled) {
                if (refreshSelectedTokenIds) {
                    setSelectedExtendedTokenIndex()
                }
            } else {
                return
            }
        } else {
            return
        }

        let _allVariants = await getAllVariants(provider)
        setAllVariants(_allVariants)

        let _tokens = await getTokens(provider)
        
        let _extendableTokens = []
        let _extendedTokens = []
        let _variantIds = await getVariantIdsOfTokens(provider, _tokens)

        _variantIds.forEach((variant, index) => {
            if (variant != "0") {
                _extendedTokens.push(_tokens[index])
            } else {
                _extendableTokens.push(_tokens[index])
            }
        })

        setExtendedTokenIndexes(_extendedTokens)
        setWalletState(walletState + 1)
    }

    const chooseExtendedToken = (token) => {
        setWarning()
        setSelectedExtendedTokenIndex(token)
    }

    const isExtendedTokenSelected = (index) => {
        return index == selectedExtendedTokenIndex
    }

    const performBlockToken = () => {
        setWarning()
        setChangeWarning()

        let senderTokenIds = [selectedExtendedTokenIndex]
        let targetTokenIds = [targetTokenId]
        let isBlockeds = [true]

        blockToken(provider, senderTokenIds, targetTokenIds, isBlockeds,
            () => {
                setWarning("Blocking, hold on!")
            },
            () => {
                setWarning("Opps, something went wrong!")
            }
            ,
            () => {
                setWarning("You've just blocked!")
                reset()
                refresh()
            })
    }

    const onTargetTokenIdChange = (tokenId) => {
        setIsTokenBlockEnabled(false)
        setTargetTokenIdText(tokenId)
        if (targetTokenIdDebounce) {
            clearInterval(targetTokenIdDebounce)
            setTargetTokenIdDebounce()
        }
        const value = parseInt(tokenId)
        if ((value == 0 || value) && value >= 0 && value <= 5062) {
            const timer = setTimeout(() => {
                setTargetTokenId(value)
                setIsTokenBlockEnabled(true)
            }, 1000);
            setTargetTokenIdDebounce(timer)
        } else {
            setTargetTokenId()
            setTargetTokenIdText()
        }
    }

    const onBlockTokenChange = () => {
        let differetItems = []
        originalBlockedTokens.forEach((originalBlockToken, i) => {
            if (originalBlockToken.isBlocked != blockedTokens[i].isBlocked) {
                differetItems.push(blockedTokens[i])
            }   
        })
        setWarning()
        setChangedBlockedTokens(differetItems)
    }

    const renderTargetToken = () => {
        if (targetTokenId || targetTokenId == 0) {
            return (
                <SocialExtensionTargetToken
                    title={"#" + targetTokenId}
                    selected={true}
                    provider={provider}
                    contractAbi={abis.ThePixels}
                    contractAddress={addressesByNetwork(provider).ThePixels}
                    tokenId={targetTokenId}
                />
            )
        }
    }

    const renderTokens = () => {
        if (extendedTokenIndexes && extendedTokenIndexes.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Choose your "the pixels inc" to block another pixel</Text>
                    <View style={styles.gallery}>
                        {extendedTokenIndexes.map((item) => {
                            return (
                                <StakeTokenButton
                                    onPress={() => chooseExtendedToken(item)}
                                    title={"#" + item}
                                    selected={isExtendedTokenSelected(item)}
                                    provider={provider}
                                    contractAbi={abis.ThePixels}
                                    contractAddress={addressesByNetwork(provider).ThePixels}
                                    tokenId={item}
                                    key={item}
                                />
                            )
                        })}
                    </View>
                </View>
            )
        } else if (extendedTokenIndexes && extendedTokenIndexes.length == 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Sorry, no available "the pixels inc"</Text>
                </View>
            )
        } else {
            return (
                <View />
            )
        }
    }

    const renderForm = () => {
        if (selectedExtendedTokenIndex) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>{"Block a pixel"}</Text>
                    <Text style={GlobalStyles.description}>{"Please enter the token id of the pixel you want to block. When you block a pixel, its messages won't appear on your pixel until you unblock it."}</Text>
                    <SpacingView />
                    <View style={styles.tokenIdContainer}>
                        <TextInput
                            maxLength={4}
                            style={styles.tokenIdInput}
                            value={targetTokenIdText}
                            onChangeText={text => onTargetTokenIdChange(text)}
                            placeholder="token id"
                        />
                        {renderTargetToken()}
                    </View>
                    <SpacingView />
                    <CTAButton
                        onPress={() => performBlockToken()}
                        title={"block token"}
                        disabled={!isTokenBlockEnabled}
                    />
                    <SpacingView />
                </View>
            )
        } else {
            return (
                <View />
            )
        }
    }

    const renderBlockedTokens = () => {
        if (blockedTokens && blockedTokens.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Blocked pixels:</Text>
                    <SpacingView />
                    <View>
                        {blockedTokens.map((item, i) => {
                            return (
                                <View key={i}>
                                    <SocialExtensionBlockedToken
                                        message={item}
                                        onChange={() => onBlockTokenChange()}
                                    />
                                    <SpacingView />
                                    <SpacingView />
                                </View>
                            )
                        })}
                    </View>
                </View>
            )
        }else if(blockedTokens) {
            return (
                <Text style={GlobalStyles.title}>This pixel has no blocked pixels</Text>
            )
        }
    }

    const performSaveBlockTokens = () => {
        setWarning()
        setChangeWarning()

        let senderTokenIds = []
        let targetTokenIds = []
        let isBlockeds = []

        changedBlockedTokens.forEach(changedBlockedToken => {
            senderTokenIds.push(selectedExtendedTokenIndex)
            targetTokenIds.push(changedBlockedToken.targetTokenId)
            isBlockeds.push(changedBlockedToken.isBlocked)
        })

        blockToken(provider, senderTokenIds, targetTokenIds, isBlockeds,
            () => {
                setChangeWarning("Saving, hold on!")
            },
            () => {
                setChangeWarning("Opps, something went wrong!")
            }
            ,
            () => {
                setChangeWarning("You've just saved!")
                reset()
                refresh()
            })
    }

    const renderSaveButton = () => {
        if (changedBlockedTokens && changedBlockedTokens.length > 0) {
            return (
                <View>
                    <CTAButton
                        onPress={() => performSaveBlockTokens()}
                        title={"save"}
                    />
                </View>
            )
        }
    }

    const render = () => {
        return (
            <View>
                {renderTokens()}
                <SpacingView />
                <SpacingView />
                <SpacingView />
                {renderForm()}
                <Text>{warning}</Text>
                <SpacingView />
                <SpacingView />
                {renderBlockedTokens()}
                {renderSaveButton()}
                <Text>{changeWarning}</Text>
            </View>
        );
    }

    return (
        <View>
          <ProviderAwareView
            provider={provider}
            render={() => {
              return render()
            }}
            chainId={0}
            includeErrorMessage
          />
        </View>
      );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    gallery: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap"
    },
    description: {
        width: 600,
        fontSize: 16,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro'
    },
    tokenIdContainer: {
        flex: 1,
        flexDirection: "row"
    },
    tokenIdInput: {
        width: 200,
        height: 50,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
        marginRight: 10
    },
    messageInput: {
        width: 400,
        height: 80,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
    },
});

export default SocialExtensionSendBlockToken;
