import React from "react";
import { Button, Image, StyleSheet, Text, View, ImageBackground } from "react-native";
import useWeb3Modal from "./hooks/useWeb3Modal";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { createGlobalState } from 'react-hooks-global-state';

import WalletButton from "./components/WalletButton";
import SpacingView from "./components/SpacingView";
import NavigationButton from "./components/NavigationButton";

import MyCollection from "./pages/MyCollection";

import AllExtensions from "./pages/Extensions/AllExtensions";
import Rewards from "./pages/Rewards/Rewards";
import ThePixelsIncDetail from "./pages/Collections/ThePixelsIncDetail";
import MetroArtContest from "./pages/ArtContest/MetroArtContest"

const image = { uri: "https://reactjs.org/logo-og.png" };

Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.style =  {
  fontSize: 18,
  letterSpacing: 0,
  fontFamily: 'Source Code Pro'
}

const initialWalletState = { walletState: 0 };
const { useGlobalState } = createGlobalState(initialWalletState);

function App() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  Text.defaultProps = Text.defaultProps || {}
  Text.defaultProps.style =  { fontFamily: 'Source Code Pro' }

  return (
    <View>
      <Router>
      <View style={[styles.content, {
        flexDirection: "column"
      }]}>
        <View style={[styles.headerContainer, {
          flexDirection: "row"
        }]}>
          <Text style={styles.title}>
            int.art
          </Text>
          <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} useGlobalState={useGlobalState} />
        </View>
        <SpacingView/>
        <Text>
          <Text style={styles.description}>
            the app for int.art owners
          </Text>
        </Text>
        <SpacingView/>
        <SpacingView/>
        <View>
          <View style={[styles.container, {
            flexDirection: "row",
            flexWrap: "wrap"
          }]}>
            <NavigationButton to="/$int" title="staking/$INT" />
            <NavigationButton to="/the-pixels-inc-extensions" title="extensions" />
            <NavigationButton to="/my-collection" title="my collection" />
          </View>
          <SpacingView/>
          <SpacingView/>
          <SpacingView/>
          <Switch>
            <Route path="/$int"><Rewards provider={provider} useGlobalState={useGlobalState}/></Route>
            <Route path="/my-collection"><MyCollection provider={provider}/></Route>
            <Route path="/the-pixels-inc-extensions"><AllExtensions provider={provider} useGlobalState={useGlobalState}/></Route>
            <Route path="/collections/the-pixels-inc/:id"><ThePixelsIncDetail provider={provider} useGlobalState={useGlobalState}/></Route>
            <Route path="/"><View/><View/></Route>
          </Switch>
        </View>
      </View>
    </Router>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    margin: '20px',
    flex: 1,
  },
  container: {
    flex: 1,
  },
  headerContainer: {
    justifyContent: "space-between"
  },
  title: {
    letterSpacing: 0,
    fontSize: 50,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 28,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
  descriptionWithStrikeThrough: {
    fontSize: 28,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro',
    textDecorationLine: 'line-through'
  }
});

export default App;
