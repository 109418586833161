import React, { useState } from 'react';
import { View, Pressable, Text, StyleSheet } from "react-native";

function Checkbox({ title, isSelected, onValueChange }) {
    const [_isSelected, _setIsSelected] = useState(isSelected);

    return (
        <Pressable
            onPress={() => {
                const newValue = !_isSelected
                _setIsSelected(newValue)
                onValueChange(newValue)
            }}
        >
            <View style={styles.container}>
                <Text style={styles.title}>{title}</Text>
                <View style={_isSelected ? styles.boxSelected : styles.boxDefault}></View>
            </View >
        </Pressable >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row"
    },
    title: {
        fontSize: 18,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: 'black',
        fontFamily: 'Source Code Pro'
    },
    boxDefault: {
        width: 20,
        height: 20,
        borderColor: "#000000",
        borderWidth: 3
    },
    boxSelected: {
        width: 20,
        height: 20,
        borderColor: "#000000",
        borderWidth: 3,
        backgroundColor: "#000000"
    }
});

export default Checkbox
