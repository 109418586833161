import React, { useState, useEffect } from 'react';
import { Pressable, Button, Image, StyleSheet, Text, View, Dimensions, Linking } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import SpacingView from './../components/SpacingView';

async function loadDudeTokenURI(index) {
  var web3 = window.web3;
  if (typeof web3 !== 'undefined') {
    web3 = new Web3(web3.currentProvider);
    const TheDudesContract = new web3.eth.Contract(abis.TheAbstractDudes, addresses.TheAbstractDudes);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return TheDudesContract.methods.tokenURI(index).call()
  }
}

function MyAbstractDude({tokenIndex}) {
  const [imageURL, setImageURL] = useState(null)
  const [name, setName] = useState(" ")

  const url = "https://opensea.io/assets/" + addresses.TheAbstractDudes + "/" + tokenIndex

  useEffect(() => {
    loadDudeTokenURI(tokenIndex).then(async tokenURI => {
      const data = await fethTokenMetada(gateawayIFPSURL(tokenURI))
      setImageURL(gateawayIFPSURL(data.image))
      setName(data.name)
    })
  }, [])

  const fethTokenMetada = async (_tokenURI) => {
    let response = await fetch(_tokenURI)
    let json = await response.json()
    return json
  }

  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          window.open(url)
        }}>
        <Text style={styles.title}>
          {name}
        </Text>
        <SpacingView/>
        <Image
          style = {styles.image}
          source = {imageURL}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginRight: 20,
    marginBottom: 20
  },
  imageContainer: {
    width: 250,
    height: 250,
  },
  image: {
    width: 250,
    height: 250,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  title: {
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
    fontSize: 16,
  },
});

function gateawayIFPSURL(url) {
  return "https://thedudes.mypinata.cloud/ipfs/" + url.split("://")[1]
}

export default MyAbstractDude;
