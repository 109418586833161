import React, { useState, useEffect } from 'react';
import { View, Pressable,Image, Text, StyleSheet } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

async function getTokenMetadata(provider, contractAbi, contractAddress, tokenId) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(contractAbi, contractAddress);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return Contract.methods.tokenURI(tokenId).call()
    }
  }
}

const getFixedIPFSURL = (url) => {
  if (url.startsWith("https://")) {
    return url
  }
  if (url.includes("ipfs://")) {
    const hash = url.split("ipfs://")[1]
    return "https://ipfs.io/ipfs/" + hash
  }
  return url;
}

function StakeTokenButton({ onPress, title, provider, contractAbi, contractAddress, tokenId, selected, type, canSelect, forcedImageSource}) {
  const [imageURL, setImageURL] = useState(null)
  const [name, setName] = useState(null)
  const [key, setKey] = useState(Date.now())

  const fethTokenMetada = async (_tokenURI) => {
    let response = await fetch(_tokenURI)
    // console.log(response);
    let json = await response.json()
    
    if (typeof json === 'string' || json instanceof String) {
      return JSON.parse(json)
    }
    return json
  }

  useEffect(() => {
    if (forcedImageSource) {
      setImageURL(forcedImageSource)
      return
    }
    getTokenMetadata(provider, contractAbi, contractAddress, tokenId).then(async tokenURI => {
      try {
        const data = await fethTokenMetada(getFixedIPFSURL(tokenURI))
        setKey(data.image)
        setImageURL(getFixedIPFSURL(data.image))
      } catch {
        setImageURL(null)
      }
    })
  }, [provider])

  if (onPress) {
    return (
      <Pressable
        onPress={onPress}
      >
        <Text style={selected ? styles.textDark : styles.textLight}>{name ? name : title}</Text>
        <Image
          style = {selected? styles.imageSelected : styles.image}
          source = {imageURL}
          key={key}
        />
      </Pressable>
    );
  }else{
    return (
      <View>
        <Text style={selected ? styles.textDark : styles.textLight}>{name ? name : title}</Text>
        <Image
          style = {selected? styles.imageSelected : styles.image}
          source = {imageURL}
          key={key}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 15,
    elevation: 3,
    backgroundColor: 'black',
    height: '30px',
    marginRight: '20px',
    marginBottom: '5px'
  },
  textLight: {
    fontSize: 18,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'gray',
    fontFamily: 'Source Code Pro',
    marginBottom: 5
  },
  textDark: {
    fontSize: 18,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    marginBottom: 5
  },

  imageContainer: {
    width: 50,
    height: 50,
  },
  image: {
    width: 120,
    height: 120,
    borderWidth: 3,
    bborderColor: "0x000000",
    marginRight: 5,
    opacity: 0.5,
    marginBottom: 15
  },
  imageSelected: {
    width: 120,
    height: 120,
    borderWidth: 5,
    bborderColor: "0x000000",
    marginRight: 5,
    opacity: 1,
    marginBottom: 15
  },
});

export default StakeTokenButton
