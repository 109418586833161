import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../components/CTAButton';
import SpacingView from '../../components/SpacingView';
import ProviderAwareView from '../../components/ProviderAwareView';
import StakeTokenButton from '../../components/StakeTokenButton';
import GlobalStyles from '../../styles/GlobalStyles';

async function unstakeTokens(provider, address, allTokens, tokens, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesContract = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return TheDudesContract.methods.withdraw(tokens).send({
      from: selectedAccount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
    .on('error', (error, receipt) => {
      onError()
    })
    .on('transactionHash', () => {
      onStarted()
    })
    .then((data) => {
      onFinished()
    })
  }else{
    onError("Please connect your wallet first!")
  }
}

async function getStakedTokens(provider, address) {
    if (typeof provider !== 'undefined') {
      const web3 = new Web3(provider);
      const TheDudesContract = new web3.eth.Contract(abis.CoreRewarder, address);
  
      const accounts = await web3.eth.getAccounts();
      const selectedAccount = accounts[0];
      if (selectedAccount) {
        return TheDudesContract.methods.stakedTokensOfOwner(selectedAccount).call()
      }
    }
  }

async function getAllTokens(provider, address) {
  if (typeof provider !== 'undefined') {

    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.tokensOfOwner(selectedAccount).call()
  }
}

function Unstake({provider, address, nftAddress, name}) {
  const [warning, setWarning] = useState(null);
  const [tokenIndexes, setTokenIndexes] = useState(null)
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);
  const [selectedTokenIndexes, setSelectedTokenIndexes] = useState([]);
  const [allTokens, setAllTokens] = useState(null);

  const refresh = async () => {
    setWarning(null)

    const _tokens = await getAllTokens(provider, address)
    setAllTokens(_tokens)

    getStakedTokens(provider, address).then(data => {
      if (data) {
        setTokenIndexes(data)
        setSelectedTokenIndexes([])
      }
    })
  }

  useEffect(() => {
    refresh()
  }, [provider])

  const chooseToken = (tokenIndex) => {
    if (isTokenSelected(tokenIndex)) {
      const currentTokens = selectedTokenIndexes.filter((item) => {
        if (item == tokenIndex) {
          return false
        }
        return true
      })
      setSelectedTokenIndexes(currentTokens)
    }else{
      const currentTokens = selectedTokenIndexes.map((item) => {return item })
      currentTokens.push(tokenIndex)
      setSelectedTokenIndexes(currentTokens)
    }
  }

  const isTokenSelected = (index) => {
    for(let i=0; i<selectedTokenIndexes.length; i++) {
      if (index == selectedTokenIndexes[i]) {
        return true
      }
    }
    return false
  }

  const performUnstakeTokens = (selectedTokenIndexes) => {
    setWarning(null)
    unstakeTokens(provider, address, allTokens, selectedTokenIndexes,
    () => {
      setWarning("Unstaking... Hold on!")
    },
    () => {
      setWarning("Opps, something went wrong dude!")
    }
    ,
    () => {
      setWarning("You've just staked!")
      refresh()
    })
  }

  const renderSaleNotActive = () => {
    return (
      <View></View>
    )
  }

  return (
    <View>
      <Text style = {GlobalStyles.title}>
          {"unstake '" + name + "'"}
      </Text>
      <ProviderAwareView
        provider={provider}
        render = {() => {
          if (tokenIndexes && tokenIndexes.length > 0) {
            return (
              <View>
                <Text style={GlobalStyles.description}>Please choose tokens to unstake. You can alternatively unstake all of the staked tokens to save gas.</Text>
                <SpacingView/>
                <SpacingView/>
                <View style={styles.gallery}>
                  {tokenIndexes.map((item) => {
                    return (
                      <StakeTokenButton
                        onPress={() => chooseToken(item)}
                        title = {"#" + item}
                        selected = {isTokenSelected(item)}
                        provider = {provider}
                        contractAbi = {abis.DummyERC721}
                        contractAddress = {nftAddress}
                        tokenId = {item}
                        key = {item}
                      />
                    )
                  })}
                </View>
                <SpacingView/>
                <SpacingView/>
                <CTAButton
                  onPress={() => performUnstakeTokens(selectedTokenIndexes)}
                  title = "unstake"
                  disabled = {selectedTokenIndexes.length == 0}
                />
                <CTAButton
                  onPress={() => performUnstakeTokens(tokenIndexes)}
                  title = "unstake all"
                  disabled = {false}
                />
                <Text>{warning}</Text>
              </View>
            )
          }else if (tokenIndexes && tokenIndexes.length == 0) {
            return (
              <View>
                <Text style={GlobalStyles.description}>No avilable tokens to unstake..</Text>
              </View>
            )
          }
          return (
            <View/>
          )
        }}
        includeErrorMessage
        renderError = {() => {
          return renderSaleNotActive()
        }}
        chainId={0}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default Unstake;
