import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from "react-native";
import { abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import ProviderAwareView from '../../../components/ProviderAwareView';
import StakeTokenButton from '../../../components/StakeTokenButton';
import GlobalStyles from '../../../styles/GlobalStyles';

const extensionId = 2

async function extendTokens(provider, tokenIds, onStarted, onError, onFinished) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    const salts = tokenIds.map((v) => { return Math.floor(Math.random() * 999999) })

    return Contract.methods.enableSocialMessages(tokenIds, salts).send({
      from: selectedAccount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
      .on('error', (error, receipt) => {
        onError()
      })
      .on('transactionHash', () => {
        onStarted()
      })
      .then((data) => {
        onFinished()
      })
  } else {
    onError("Please connect your wallet first!")
  }
}

async function approveToken(provider, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    const maxAmount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"

    return Contract.methods.approve(addressesByNetwork(provider).ThePixelsIncExtensionStorage, maxAmount).send({
      from: selectedAccount
    })
      .on('error', (error, receipt) => {
        onError()
      })
      .on('transactionHash', () => {
        onStarted()
      })
      .then((data) => {
        onFinished()
      })
  } else {
    onError("Please connect your wallet first!")
  }
}

async function getIsApprovedForAll(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return Contract.methods.allowance(selectedAccount, addressesByNetwork(provider).ThePixelsIncExtensionStorage).call()
    }
  }
}

async function getAllVariants(provider, orders) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

    const accounts = await web3.eth.getAccounts();
    return Contract.methods.variantsOfExtension(1, [1, 2, 3, 4, 5, 6]).call()
  }
}

async function getTokens(provider) {
  if (typeof provider !== 'undefined') {

    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await Contract.methods.tokensOfOwner(selectedAccount).call()
  }
}

async function getVariantIdsOfTokens(provider, tokenIds) {
  if (typeof provider !== 'undefined') {

    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await Contract.methods.currentVariantIdsOf(2, tokenIds).call()
  }
}

async function getINTBalance(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);

    const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await Contract.methods.balanceOf(selectedAccount).call()
  }
}

async function getExtension(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return Contract.methods.extensions(extensionId).call()
    }
  }
}

function SocialExtensionSendExtend({ provider, useGlobalState }) {
  const [warning, setWarning] = useState(null);
  const [tokenIndexes, setTokenIndexes] = useState(null)
  const [extendedTokenIndexes, setExtendedTokenIndexes] = useState(null)

  const [intBalance, setIntBalance] = useState(0);
  const [isEnabled, setIsEnabled] = useState(null)
  const [selectedTokenIndexes, setSelectedTokenIndexes] = useState([]);
  const [isExtensionDisabled, setIsExtensionDisabled] = useState(false);
  const [totalOrderPrice, setTotalOrderPrice] = useState()
  const [allVariants, setAllVariants] = useState([])
  const [walletState, setWalletState] = useGlobalState("walletState")
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);

  const refresh = async (refreshSelectedTokenIds) => {
    if (!provider) {
      return
    }

    let _extension = await getExtension(provider)

    if (_extension) {
      setIsEnabled(_extension.isEnabled);
      if (_extension.isEnabled) {
        if (refreshSelectedTokenIds) {
          setSelectedTokenIndexes([])
        }
      } else {
        return
      }
    } else {
      return
    }

    const _isApprovedForAll = await getIsApprovedForAll(provider)
    if (_isApprovedForAll && _isApprovedForAll > 0) {
      setIsApprovedForAll(true)
    }

    let _allVariants = await getAllVariants(provider)
    setAllVariants(_allVariants)

    let _tokens = await getTokens(provider)
    setIntBalance(await getINTBalance(provider))

    let _extendableTokens = []
    let _extendedTokens = []
    let _variantIds = await getVariantIdsOfTokens(provider, _tokens)

    _variantIds.forEach((variant, index) => {
      if (variant != "0") {
        _extendedTokens.push(_tokens[index])
      } else {
        _extendableTokens.push(_tokens[index])
      }
    })

    setTokenIndexes(_extendableTokens)
    setExtendedTokenIndexes(_extendedTokens)
    setWalletState(walletState + 1)
  }

  useEffect(() => {
    refresh(true)
  }, [provider])

  useEffect(() => {
    if (allVariants.length == 0) {
      return
    }
    const firstVariant = allVariants[0]
    const BN = Web3.utils.BN;
    const totalPrice = new BN(firstVariant.cost).mul(new BN(selectedTokenIndexes.length))
    setTotalOrderPrice(totalPrice)

  }, [selectedTokenIndexes])

  const performExtendTokens = () => {
    setWarning(null)
    extendTokens(provider, selectedTokenIndexes,
      () => {
        setWarning("Extending, hold on!")
        setIsExtensionDisabled(true)
      },
      () => {
        setWarning("Opps, something went wrong!")
        setIsExtensionDisabled(false)
      }
      ,
      () => {
        setWarning("You've just extended!")
        setIsExtensionDisabled(false)
        refresh(true)
      })
  }

  const performAppprove = () => {
    setWarning(null)
    approveToken(provider,
      () => {
        setWarning("Approving, hold on!")
      },
      () => {
        setWarning("Opps, something went wrong!")
      }
      ,
      () => {
        setWarning("You've just approved!")
        refresh(false)
      })
  }

  const hasEnoughBalance = () => {
    const BN = Web3.utils.BN;
    const intBalanceBN = new BN(intBalance)

    if (intBalanceBN.gte(totalOrderPrice)) {
      return true;
    }
    return false;
  }

  const renderExtensionNotActive = () => {
    return (
      <View>
        <SpacingView />
        <SpacingView />
        <Text style={GlobalStyles.title}>This extension is ended :( Please stay tuned for more extensions!</Text>
      </View>
    )
  }

  const chooseToken = (tokenIndex) => {
    if (selectedTokenIndexes.includes(tokenIndex)) {
      const newTokenIndexes = selectedTokenIndexes.filter(selectedTokenIndex => {
        return selectedTokenIndex != tokenIndex
      })
      setSelectedTokenIndexes(newTokenIndexes)
    } else {
      const newTokenIndexes = selectedTokenIndexes.map(t => t)
      newTokenIndexes.push(tokenIndex)
      setSelectedTokenIndexes(newTokenIndexes)
    }
  }

  const isTokenSelected = (index) => {
    return selectedTokenIndexes.includes(index)
  }

  const renderTokens = () => {
    if (tokenIndexes && extendedTokenIndexes && tokenIndexes.length > 0) {
      return (
        <View>
          <Text style={GlobalStyles.title}>Choose your "the pixels inc" to extend</Text>
          <View style={styles.gallery}>
            {tokenIndexes.map((item) => {
              return (
                <StakeTokenButton
                  onPress={() => chooseToken(item)}
                  title={"#" + item}
                  selected={isTokenSelected(item)}
                  provider={provider}
                  contractAbi={abis.ThePixels}
                  contractAddress={addressesByNetwork(provider).ThePixels}
                  tokenId={item}
                  key={item}
                />
              )
            })}
          </View>
        </View>
      )
    } else if (tokenIndexes && tokenIndexes.length == 0) {
      return (
        <View>
          <Text style={GlobalStyles.title}>Sorry, no available "the pixels inc"</Text>
        </View>
      )
    } else {
      return (
        <View />
      )
    }
  }

  const renderExtendButon = () => {
    if (totalOrderPrice && totalOrderPrice != 0) {
      const totalPrice = Web3.utils.fromWei(totalOrderPrice)
      if (hasEnoughBalance()) {
        if (!isApprovedForAll) {
          return (
            <CTAButton
              onPress={() => performAppprove()}
              title={"approve spending $INT"}
              disabled={false}
            />
          )
        }
        return (
          <CTAButton
            onPress={() => performExtendTokens(selectedTokenIndexes[0])}
            title={"extend - " + totalPrice + " $INT"}
            disabled={selectedTokenIndexes.length == 0 || isExtensionDisabled}
          />
        )
      } else {
        return (
          <View>
            <CTAButton
              onPress={() => performExtendTokens(selectedTokenIndexes[0])}
              title={"not enough $INT"}
              disabled={true}
            />
            <Text>{"You need " + totalPrice + " $INT to extend"}</Text>
          </View>
        )
      }
    } else {
      return (
        <CTAButton
          onPress={() => performExtendTokens(selectedTokenIndexes[0])}
          title={"extend"}
          disabled={selectedTokenIndexes.length == 0 || isExtensionDisabled}
        />
      )
    }
  }

  const render = () => {
    if (isEnabled) {
      return (
        <View>
          {renderTokens()}
          {renderExtendButon()}
          <Text>{warning}</Text>
        </View>
      )
    } else if (isEnabled == false) {
      return (
        <View>
          <SpacingView />
          <SpacingView />
          {renderExtensionNotActive()}
        </View>
      )
    } else {
      return (
        <View />
      )
    }
  }

  return (
    <View>
      <ProviderAwareView
        provider={provider}
        render={() => {
          return render()
        }}
        chainId={0}
        includeErrorMessage
      />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  description: {
    width: 600,
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
  tokenIdInput: {
    width: 200,
    height: 50,
    fontSize: 16,
    letterSpacing: 0,
    borderWidth: 5,
    fontFamily: 'Source Code Pro',
    padding: 10,
  },
  messageInput: {
    width: 400,
    height: 200,
    fontSize: 16,
    letterSpacing: 0,
    borderWidth: 5,
    fontFamily: 'Source Code Pro',
    padding: 10,
  },
});

export default SocialExtensionSendExtend;
