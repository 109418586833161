import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import { addresses, abis } from "@project/contracts";

import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';
import stakingImg from '../../assets/staking.png'

function RewardsAbout() {
  return (
    <View>
      <View style={styles.content}>
        <Text style={GlobalStyles.title}>
          {"About $INT\n"}
        </Text>
        <Text style={GlobalStyles.description}>
          {"$INT is the currency of whole 'the pixels inc' and 'the dudes' universe. Every drop, every new feature will require you to spend $INT. Don't worry! By owning or staking 'the dudes' or 'the pixels inc', you can earn $INT!"}
        </Text>
        <SpacingView/>
        <SpacingView/>
        <Image
              style = {styles.image}
              source = {stakingImg}
            />
        <SpacingView/>
        <SpacingView/>
        <Text style={GlobalStyles.title}>
          {"Daily $INT Rewards"}
        </Text>
        <Text style={GlobalStyles.description}>
          {"By holding 'the pixels inc' and 'the dudes' you earn $INT daily. You can use $INT to join giveaways, as tickets for special drops and most importantly to get extensions for your pixels. Check 'claim rewards' page to see how much $INT you earned so far!"}
        </Text>
        <SpacingView/>
        <SpacingView/>
        <Text style={GlobalStyles.title}>
          {"Staking "}
        </Text>
        <Text style={GlobalStyles.description}>
          {"To boost your daily $INT rewards you can stake your 'the pixels inc' and 'the dudes'. Staking multiplies your daily reward by '2'. This way you can claim rare extensions!"}
        </Text>
      </View>
      <SpacingView/>
      <SpacingView/>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: "grid"
  },
  exampleContainer: {
    marginTop: 10,
    flex: 1,
    flexShrink: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    width: "auto",
    minWidth: 50,
  },
  image: {
    height: 450,
    resizeMode: 'contain'
  },
});

export default RewardsAbout;
