import React, { useState, useEffect } from 'react';
import { Button, Image, StyleSheet, Text, TextInput, View, Linking } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../components/CTAButton';
import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';

async function signMessage(provider, message) {
	return new Promise(async (resolve, reject) => {
		if (typeof web3 !== 'undefined') {
			const web3 = new Web3(provider);

			const accounts = await web3.eth.getAccounts();
			const selectedAccount = accounts[0];
			const msgParams = [
				{
					type: 'string',
					name: 'Message',
					value: "I would like to vote for metro art contest."
				}]

			let domain = [
				{ name: "name", type: "string" },
				{ name: "version", type: "string" },
				{ name: "chainId", type: "uint256" }
			]

			let staticMessage = [
				{ "name": 'Request', "type": 'string' },
			]

			let domainData = {
				name: "int art",
				version: "1",
				chainId: 1
			}

			let message = {
				Request: "I would like to vote for metro art contest."
			}

			let eip712TypedData = {
				types: {
					EIP712Domain: domain,
					StaticMessage: staticMessage
				},
				domain: domainData,
				primaryType: "StaticMessage",
				message: message
			}

			let data = JSON.stringify(eip712TypedData)

			web3.currentProvider.sendAsync({
				method: 'eth_signTypedData_v4',
				params: [selectedAccount, data],
				from: selectedAccount,
			}, function (err, result) {
				if (err) {
					reject()
				}
				if (result) {
					console.log(result);
					resolve({ result: result.result })
				}
			})
		}
	})
}

function MetroArtContest({ provider }) {
	const [vote0, onVote0Change] = React.useState('');
	const [vote1, onVote1Change] = React.useState('');
	const [vote2, onVote2Change] = React.useState('');
	const [vote3, onVote3Change] = React.useState('');
	const [vote4, onVote4Change] = React.useState('');

	const [warning, setWarning] = useState(null);

	const validateVote = (value) => {
		const fvalue = parseInt(value)
		if (fvalue > 0 && fvalue <= 171) {
			return fvalue
		}
		return undefined
	}

	const updateInput = (input, updater) => {
		const value = validateVote(input)
		if (value) {
			updater(value)
		}
	}

	const validateALlVotes = () => {
		if (validateVote(vote1) &&
			validateVote(vote2) &&
			validateVote(vote3) &&
			validateVote(vote4)) {
			return true
		}
		return false
	}

	const performSign = async () => {
		setWarning(null)
		if (!validateALlVotes()) {
			setWarning("your votes are not valid!")
			return
		}
		const { result, message } = await signMessage(provider)
		console.log("signedMesssage", result, message);
		const response = await sendInterest(message, result)
		if (response && response.success) {
			setWarning(response.message)
			if (response.success) {
				setWarning("You've just successfully voted!")
			}
		} else {
			setWarning("Something went wrong...")
		}
	}

	const sendInterest = async (message, sig) => {
		const data = {
			message: message,
			sig: sig,
			vote0: vote0,
			vote1: vote1,
			vote2: vote2,
			vote3: vote3,
			vote4: vote4,
		}
		const url = "https://api.int.art/the-metro/art-contest"
		const response = await fetch(url, {
			method: "POST",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data),
		})
		return response.json()
	}

	return (
		<View style={styles.content} >
			<View>
				<Text style={GlobalStyles.title}>
					{"the metro art contest voting"}
				</Text>
				<Text style={GlobalStyles.description}>
					{"Please check the discord channel to see the art contest submissions. Enter the ids of your top 5 favorite submissions and submit your votes!\n\n"}
					<SpacingView />
					{"Please use the wallet address that owns or stakes dudes/pixels."}
				</Text>
				<SpacingView />
				<SpacingView />
				<SpacingView />
				<View>
					<Text style={GlobalStyles.description}>
						{"Submission number #1: "}
					</Text>
					<TextInput
						style={styles.input}
						onChangeText={(v) => updateInput(v, onVote0Change)}
						value={vote0}
						placeholder=""
						keyboardType="numeric"
					>
					</TextInput>
				</View>
				<SpacingView />
				<View>
					<Text style={GlobalStyles.description}>
						{"Submission number #2: "}
					</Text>
					<TextInput
						style={styles.input}
						onChangeText={(v) => updateInput(v, onVote1Change)}
						value={vote1}
						placeholder=""
						keyboardType="numeric"
					>
					</TextInput>
				</View>
				<SpacingView />
				<View>
					<Text style={GlobalStyles.description}>
						{"Submission number #3: "}
					</Text>
					<TextInput
						style={styles.input}
						onChangeText={(v) => updateInput(v, onVote2Change)}
						value={vote2}
						placeholder=""
						keyboardType="numeric"
					>
					</TextInput>
				</View>
				<SpacingView />
				<View>
					<Text style={GlobalStyles.description}>
						{"Submission number #4: "}
					</Text>
					<TextInput
						style={styles.input}
						onChangeText={(v) => updateInput(v, onVote3Change)}
						value={vote3}
						placeholder=""
						keyboardType="numeric"
					>
					</TextInput>
				</View>
				<SpacingView />
				<View>
					<Text style={GlobalStyles.description}>
						{"Submission number #5: "}
					</Text>
					<TextInput
						style={styles.input}
						onChangeText={(v) => updateInput(v, onVote4Change)}
						value={vote4}
						placeholder=""
						keyboardType="numeric"
					>
					</TextInput>
				</View>
			</View>
			<SpacingView />
			<SpacingView />
			<CTAButton
				onPress={() => performSign()}
				title="submit votes!"
			/>
			<Text>{warning}</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	content: {
		maxWidth: 800
	},
	bar: {
		flexDirection: "row",
		flexWrap: "wrap"
	},
	title: {
		fontSize: 18,
		letterSpacing: 0,
		fontWeight: 600,
		fontFamily: 'Source Code Pro'
	},
	description: {
		fontSize: 16,
		letterSpacing: 0,
		fontFamily: 'Source Code Pro'
	},
	description_link: {
		textDecorationLine: "underline"
	},
	input: {
		width: 100,
		height: 50,
		borderWidth: 5,
		borderColor: "black",
		fontSize: 16,
		padding: 5,
		fontFamily: 'Source Code Pro',
		marginTop: 2
	},
	gallery: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap"
	},
});

export default MetroArtContest;
