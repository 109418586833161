import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../components/CTAButton';
import SpacingView from '../../components/SpacingView';
import ProviderAwareView from '../../components/ProviderAwareView';
import StakeTokenButton from '../../components/StakeTokenButton';
import GlobalStyles from '../../styles/GlobalStyles';

async function stakeTokens(provider, address, allTokens, tokens, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesContract = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return TheDudesContract.methods.stake(tokens).send({
      from: selectedAccount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
    .on('error', (error, receipt) => {
      onError()
    })
    .on('transactionHash', () => {
      onStarted()
    })
    .then((data) => {
      onFinished()
    })
  }else{
    onError("Please connect your wallet first!")
  }
}

async function approveToken(provider, address, nftAddres, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesContract = new web3.eth.Contract(abis.DummyERC721, nftAddres);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return TheDudesContract.methods.setApprovalForAll(address, true).send({
      from: selectedAccount
    })
    .on('error', (error, receipt) => {
      onError()
    })
    .on('transactionHash', () => {
      onStarted()
    })
    .then((data) => {
      onFinished()
    })
  }else{
    onError("Please connect your wallet first!")
  }
}

async function getIsEnabled(provider, address, tokens) {
  if (typeof provider !== 'undefined' && address) {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.isStakingEnabled().call()
  }
}

async function getIsApprovedForAll(provider, address, nftAddress) {
  if (typeof provider !== 'undefined' && nftAddress) {
    const web3 = new Web3(provider);
    const TheDudesContract = new web3.eth.Contract(abis.DummyERC721, nftAddress);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return TheDudesContract.methods.isApprovedForAll(selectedAccount, address).call()
    }
  }
}

async function getTokens(provider, address) {
  if (typeof provider !== 'undefined' && address) {
    const web3 = new Web3(provider);
    const TheDudesContract = new web3.eth.Contract(abis.DummyERC721, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return TheDudesContract.methods.tokensOfOwner(selectedAccount).call()
    }
  }
}

async function getAllTokens(provider, address) {
  if (typeof provider !== 'undefined' && address) {

    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.tokensOfOwner(selectedAccount).call()
  }
}

function Stake({provider, address, nftAddress, name}) {
  const [isEnabled, setIsEnabled] = useState(undefined);
  const [warning, setWarning] = useState(null);
  const [tokenIndexes, setTokenIndexes] = useState(null)
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);
  const [selectedTokenIndexes, setSelectedTokenIndexes] = useState([]);
  const [allTokens, setAllTokens] = useState(null);

  const refresh = async () => {
    setWarning(null)

    const _isEnabled = await getIsEnabled(provider, address);
    setIsEnabled(_isEnabled)
    const _tokens = await getAllTokens(provider, address)
    setAllTokens(_tokens)

    getTokens(provider, nftAddress).then(data => {
      if (data) {
        setTokenIndexes(data)
        setSelectedTokenIndexes([])
      }
    })

    getIsApprovedForAll(provider, address, nftAddress).then(data => {
      if (data) {
        setIsApprovedForAll(true)
      }
    })
  }

  useEffect(() => {
    refresh()
  }, [provider])

  const chooseToken = (tokenIndex) => {
    if (isTokenSelected(tokenIndex)) {
      const currentTokens = selectedTokenIndexes.filter((item) => {
        if (item == tokenIndex) {
          return false
        }
        return true
      })
      setSelectedTokenIndexes(currentTokens)
    }else{
      const currentTokens = selectedTokenIndexes.map((item) => {return item })
      currentTokens.push(tokenIndex)
      setSelectedTokenIndexes(currentTokens)
    }
  }

  const isTokenSelected = (index) => {
    for(let i=0; i<selectedTokenIndexes.length; i++) {
      if (index == selectedTokenIndexes[i]) {
        return true
      }
    }
    return false
  }

  const performAppprove = () => {
    setWarning(null)
    approveToken(provider, address, nftAddress,
    () => {
      setWarning("Approving, hold on!")
    },
    () => {
      setWarning("Opps, something went wrong dude!")
    }
    ,
    () => {
      setWarning("You approved your tokens dude!")
      refresh()
    })
  }

  const performStake = (selectedTokenIndexes) => {
    setWarning(null)
    stakeTokens(provider, address, allTokens, selectedTokenIndexes,
    () => {
      setWarning("Staking, hold on!")
    },
    () => {
      setWarning("Opps, something went wrong dude!")
    }
    ,
    () => {
      setWarning("You've just staked!")
      refresh()
    })
  }

  const renderSaleNotActive = () => {
    return (
      <View></View>
    )
  }

  const renderButtons = () => {
    if (isEnabled) {
      return (
        <View>
          <CTAButton
            onPress={() => performStake(selectedTokenIndexes)}
            title = "stake"
            disabled = {selectedTokenIndexes.length == 0}
          />
          <CTAButton
            onPress={() => performStake(tokenIndexes)}
            title = "stake all"
            disabled = {false}
          />
        </View>
      )
    }else{
      return (
        <CTAButton
            onPress={() => {}}
            title = "staking not enabled"
            disabled = {true}
          />
      )
    }
  }
  

  return (
    <View>
      <Text style = {GlobalStyles.title}>
          {"stake '" + name + "'"}
      </Text>
      <ProviderAwareView
        provider={provider}
        render = {() => {
          if (tokenIndexes && tokenIndexes.length > 0) {
            if (!isEnabled) {
              return (
                <View>
                  <Text style={GlobalStyles.description}>{"Staking is not enabled yet."}</Text>
                </View>
              )
            }else if (!isApprovedForAll) {
              return (
                <View>
                  <CTAButton
                    onPress={() => performAppprove()}
                    title = "approve"
                  />
                  <Text>{warning}</Text>
                </View>
              )
            }else{
              return (
                <View>
                  <Text style={GlobalStyles.description}>Please choose tokens to stake. You can alternatively stake all of the available tokens to save gas.</Text>
                  <SpacingView/>
                  <View style={styles.gallery}>
                    {tokenIndexes.map((item) => {
                      return (
                        <StakeTokenButton
                          onPress={() => chooseToken(item)}
                          title = {"#" + item}
                          selected = {isTokenSelected(item)}
                          provider = {provider}
                          contractAbi = {abis.DummyERC721}
                          contractAddress = {nftAddress}
                          tokenId = {item}
                          key = {item}
                        />
                      )
                    })}
                  </View>
                  <SpacingView/>
                  <SpacingView/>
                  {renderButtons()}
                  <Text>{warning}</Text>
                </View>
              )
            }
          }else if (tokenIndexes && tokenIndexes.length == 0) {
            return (
              <View>
                <Text style={GlobalStyles.description}>No avilable tokens to stake..</Text>
              </View>
            )
          }

          return (
            <View/>
          )
        }}
        includeErrorMessage
        renderError = {() => {
          return renderSaleNotActive()
        }}
        chainId={0}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
});

export default Stake;
