import React, { useState, useEffect } from 'react';
import { Pressable, View, Text, StyleSheet} from "react-native";
import { useHistory, useLocation } from "react-router-dom";

import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

async function getBalance(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);

    
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.balanceOf(selectedAccount).call()
  }
}

async function getTokens(provider, address) {
  if (typeof provider !== 'undefined') {

    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.tokensOfOwner(selectedAccount).call()
  }
}

async function getReward(provider, address, tokens) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.earned(tokens).call()
  }
}

async function rewardsOf(provider, address) {
  const _tokens = await getTokens(provider, address)
  const _reward = await getReward(provider, address, _tokens);
  return _reward
}

const formatBalance = (balance) => {
  if (balance.includes(".")) {
    let left = balance.split(".")[0]
    let right = balance.split(".")[1]

    if (right.length > 2) {
      right = right.slice(0, 2)
    }
    
    return left + "." + right
  }else{
    return balance
  }
}

function WalletButton({provider, loadWeb3Modal, logoutOfWeb3Modal, useGlobalState}) {
  const [reward, setReward] = useState(0);
  const [balance, setBalance] = useState(0);
  const [walletState, setWalletState] = useGlobalState("walletState")

  const history = useHistory();
  const location = useLocation();

  const refreshState = async () => {
    const _thePixelsIncRewards = await rewardsOf(provider, addressesByNetwork(provider).ThePixelsIncRewarder)
    const _theDudesRewards = await rewardsOf(provider, addressesByNetwork(provider).TheDudesRewarder)
    const _balance = await getBalance(provider)

    const BN = Web3.utils.BN;
    const totalRewardFormatted = new BN(_thePixelsIncRewards).add(new BN(_theDudesRewards)).toString();
    setReward(formatBalance(Web3.utils.fromWei(totalRewardFormatted)))
    setBalance(formatBalance(Web3.utils.fromWei(_balance)))
  }

  useEffect(() => {
    if (typeof provider !== 'undefined') {
      refreshState()
    }
  }, [provider, walletState])

  const navigateToINTPage = () => {
    history.push("/$int/claim")
  }

  const renderReward = () => {
    return (
      <View style={styles.container}>
        <Pressable style={styles.intContainer} onPress={ () => { navigateToINTPage() }}>
          <Text style={styles.intText}>
              {"Claimable: " + reward + " $INT"}
          </Text>
        </Pressable>
      </View>
    )
  }
  const renderINTBalance = () => {
    return (
      <View style={styles.container}>
        <Pressable style={styles.intContainer} onPress={ () => { navigateToINTPage() }}>
          <Text style={styles.intText} >
              {balance + " $INT"}
          </Text>
        </Pressable>
      </View>
    )
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        {renderReward()}
        {renderINTBalance()}
        <View style={styles.buttonContainer}>
          <Text
            onPress = { () => {
              if (!provider) {
                loadWeb3Modal();
              } else {
                logoutOfWeb3Modal();
              }
            }}
            style={styles.text}>{!provider ? "Connect Wallet" : "Disconnect Wallet"}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: 'center',
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: 'center',
  },
  intContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "black",
    paddingVertical: 12,
    paddingHorizontal: 32,
    color: "white",
    marginRight: '20px'
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 16,
    marginTop: 2,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
  intText: {
    fontSize: 16,
    marginTop: 2,
    color: 'white',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
});

export default WalletButton
