export default {
    title: {
        fontSize: 24,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro',
        marginBottom: 15
    },
    description: {
        fontSize: 20,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro'
    },
    descriptionLink: {
        fontSize: 20,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro',
        fontWeight: 600,
        textDecoration: "underline"
    },
};
