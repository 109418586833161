import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View, TextInput } from "react-native";
import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	useParams,
	useHistory
  } from "react-router-dom";

import CTAButton from '../../components/CTAButton';
import SpacingView from '../../components/SpacingView';
import ProviderAwareView from '../../components/ProviderAwareView';
import ExtensionButtonSimple from '../../components/ExtensionButtonSimple';

import variantData from '../../assets/extensions/background/variants.json'

async function getDNA(provider, tokenId) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);

		const Contract = new web3.eth.Contract(abis.ThePixelsIncMetadataProvider, addressesByNetwork(provider).ThePixelsIncMetadataProvider);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.fullDNAOfToken(tokenId).call()
	}
}


async function getINTBalance(provider) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);

		const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.balanceOf(selectedAccount).call()
	}
}

async function performExtendToken(provider, tokenId, variant, onStarted, onError, onFinished) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return Contract.methods.extendWithVariant(selectedAccount, 0, tokenId, variant.variantId, false, 0).send({
			from: selectedAccount,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
			.on('error', (error, receipt) => {
				onError()
			})
			.on('transactionHash', () => {
				onStarted()
			})
			.then((data) => {
				onFinished()
			})
	} else {
		onError("Please connect your wallet first!")
	}
}

async function getVariantStatusses(provider, tokenId, variants) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		const tokenIds = variants.map((order) => { return tokenId })
		const variantIds = variants.map((variant) => { return variant.variantId })
		const useCollectionTokens = variants.map((order) => { return false })
		const collectionTokenIds = variants.map((order) => { return 0 })

		return Contract.methods.variantDetails(selectedAccount, 0, tokenIds, variantIds, useCollectionTokens, collectionTokenIds).call()
	}
}

async function getTokens(provider) {
	if (typeof provider !== 'undefined') {

		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.tokensOfOwner(selectedAccount).call()
	}
}

async function getCollectionBalanceOf(provider, collectionAddress) {
	if (typeof provider !== 'undefined') {

		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.DummyERC721, collectionAddress)

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.balanceOf(selectedAccount).call()
	}
}

async function extendPixel(provider, tokenId, onStarted, onError, onFinished) {
	if (typeof web3 !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixels, addressesByNetwork(provider).ThePixels);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return Contract.methods.updateDNAExtension(tokenId).send({
			from: selectedAccount,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
			.on('error', (error, receipt) => {
				onError()
			})
			.on('transactionHash', () => {
				onStarted()
			})
			.then((data) => {
				onFinished()
			})
	} else {
		onError("Please connect your wallet first!")
	}
}

const getExtensionVariantFromDNA = (dna, beginIndex, endIndex) => {
	if (!dna.includes("_")) {
		return null;
	}
	let extensionPart = dna.split("_")[1]
	if (extensionPart && extensionPart.length > 0) {
		return extensionPart.substring(beginIndex, endIndex);
	}
	return null;
}

function ThePixelsIncDetail({ provider, useGlobalState }) {
	let { id } = useParams();
	let history = useHistory();

	const [warning, setWarning] = useState(null);
	const [tokenIndexes, setTokenIndexes] = useState([])
	const [selectedToken, setSelectedToken] = useState(null);
	const [selectedDNA, setSelectedDNA] = useState(null);
	const [visualSRC, setVisualSRC] = useState();
	const [isCapturing, setIsCapturing] = useState();
	const [backgroundVariants, setBackgroundVariants] = useState()
	const [selectedVariant, setSelectedVariant] = useState()
	const [currentVariant, setCurrentVariant] = useState()
	const [isExtensionDisabled, setIsExtensionDisabled] = useState(false);
	const [captureProgress, setCaptureProgress] = useState(0)

	useEffect(() => {
		refresh()
	}, [provider])

	useEffect(() => {
		refreshDNA()
	}, [selectedToken])

	const refresh = async () => {
		let _tokens = await getTokens(provider)
		setTokenIndexes(_tokens)

		if (id) {
			setSelectedToken(id)
			refreshDNA()
		}
	}

	const refreshDNA = async () => {
		if (provider && selectedToken) {
			const dna = await getDNA(provider, selectedToken)
			const allVariants = await getVariantStatusses(provider, selectedToken, variantData)
			let claimedVariants = []
			allVariants[1].forEach((variant, index) => {
				if(variant.isAlreadyClaimed) {
					claimedVariants.push(variantData[index])
				}
			})
			const currentExtensionVariant = getExtensionVariantFromDNA(dna, 1, 4)
			if (currentExtensionVariant) {
				const foundVariant = variantData.filter((variant) => {
					if (variant.variantId == currentExtensionVariant) {
						return true
					}
					return false
				})[0]
				setCurrentVariant(foundVariant)
				setSelectedVariant(foundVariant)
			}
			setBackgroundVariants(claimedVariants)
			setSelectedDNA(dna)
			setVisualSRC("https://thedudes.art/playground/pixels-detail/?tokenId=" + selectedToken + "?dna=" + dna)
		}
	}

	// const renderTokens = () => {
	// 	if (tokenIndexes && tokenIndexes.length > 0) {
	// 		return (
	// 			<View>
	// 				<Text style={styles.title}>Your 'the pixels inc'</Text>
	// 				<SpacingView/>
	// 				<View style={styles.gallery}>
	// 					{tokenIndexes.map((item) => {
	// 						return (
	// 							<StakeTokenButton
	// 								onPress={() => chooseToken(item)}
	// 								title={"#" + item}
	// 								selected={isTokenSelected(item)}
	// 								provider={provider}
	// 								contractAbi={abis.ThePixels}
	// 								contractAddress={addressesByNetwork(provider).ThePixels}
	// 								tokenId={item}
	// 								key={item}
	// 							/>
	// 						)
	// 					})}
	// 				</View>
	// 				<SpacingView/>
	// 				<SpacingView/>
	// 				<SpacingView/>
	// 			</View>
	// 		)
	// 	}
	// }

	const chooseToken = (tokenIndex) => {
		history.push("/collections/the-pixels-inc/" + tokenIndex)
		setSelectedToken(tokenIndex)
	}

	const isTokenSelected = (index) => {
		return index == selectedToken
	}

	const isVariantSelected = (variant) => {
		return variant == selectedVariant
	}

	const canSelectVariant = (variant) => {
		return true;
	}

	const chooseVariant = (variant) => {
		if (selectedToken) {
			setSelectedVariant(variant)
		}
	}

	const startCapturing = () => {
		setIsCapturing(true)
		document.getElementById("visualFrame").contentWindow.startCapturing()
		document.getElementById("visualFrame").contentWindow.capturingIsOnProgress = (progress) => {
			console.log("progress", progress);
			setCaptureProgress(progress)
		}
	}

	const stopCapturing = () => {
		setIsCapturing(false)
		document.getElementById("visualFrame").contentWindow.stopCapturing()
	}

	const viewonOpenSea = () => {
		window.open("https://opensea.io/assets/0x7ff2a00ff543f913b76010a05b5446e36d403675/" + selectedToken, '_blank');
	}

	const viewOnLooksRare = () => {
		window.open("https://looksrare.org/collections/0x7Ff2a00Ff543f913b76010A05b5446E36D403675/" + selectedToken, '_blank');
	}

	const renderVisual = () => {
		if (selectedToken && visualSRC && selectedDNA) {
			return (
				<View>
					<Text style={styles.title}>{"the pixel inc " + ("#" + selectedToken)}</Text>
					<Text style={styles.description}>{"DNA: " + selectedDNA}</Text>
					<SpacingView/>
					<div>
						<iframe id="visualFrame" src={visualSRC} height={500} width={500} style={{ borderStyle: "solid", borderWidth: 3, borderColor: "0x000000", overflow: "hidden" }} scroll="no" />
					</div>
				</View>
			)
		}
	}

	const renderProgress = () => {
		if (captureProgress != 0 && captureProgress != 1) {
			return (
				<View>
					<Text>{"rendering the GIF... " + Math.floor(captureProgress * 100) + "%"}</Text>
				</View>
			)
		}
	}

	const renderActionButtons = () => {
		if (selectedToken && visualSRC && selectedDNA) {
			if(isCapturing) {
				return (
					<View>
						<CTAButton
							onPress={() => stopCapturing()}
							title="stop recording"
							disabled={false}
						/>
					</View>
				)
			}else{
				return (
					<View>
						<CTAButton
							onPress={() => startCapturing()}
							title="start recording"
							disabled={false}
						/>
					</View>
				)
			}
		}
	}

	const renderExtensionVariants = () => {
		if (backgroundVariants && backgroundVariants.length > 0) {
			return (
				<View>
					<Text style={styles.title}>{"claimed backgrounds"}</Text>
					<SpacingView />
					<View style={styles.gallery}>
						{backgroundVariants.map((item) => {
							return (
								<ExtensionButtonSimple
									variant={item}
									selected={isVariantSelected(item)}
									canSelect={canSelectVariant(item)}
									onPress={() => chooseVariant(item)}
								/>
							)
						})}
					</View>
					<SpacingView />
					<SpacingView />
				</View>
			)
		}
	}

	const renderExtensionVariantSwap = () => {
		if (backgroundVariants && backgroundVariants.length > 0 && selectedVariant) {
			if (currentVariant != selectedVariant) {
				return (
					<View>
						<CTAButton
							onPress={() => extendToken()}
							title="use this background"
							disabled={isExtensionDisabled}
						/>
					</View>
				)
			}
		}
	}

	const renderViewButtons = () => {
		if (selectedToken && visualSRC && selectedDNA) {
			return (
				<View>
					<CTAButton
						onPress={() => viewonOpenSea()}
						title="view on OpenSea"
					/>
					<CTAButton
						onPress={() => viewOnLooksRare()}
						title="view on LooksRare"
					/>
				</View>
			)
		}
	}

	const extendToken = () => {
		setWarning(null)
		performExtendToken(provider, selectedToken, selectedVariant,
			() => {
				setWarning("Extending, hold on!")
				setIsExtensionDisabled(true)
			},
			() => {
				setWarning("Opps, something went wrong!")
				setIsExtensionDisabled(false)
			}
			,
			() => {
				setWarning("You've just extended!")
				setIsExtensionDisabled(false)
				refresh()
			})
	}


	return (
		<View style={styles.content}>
			<ProviderAwareView
				provider={provider}
				render={() => {
					return (
						<View>
							{renderVisual()}
							<SpacingView/>
							{renderActionButtons()}
							{renderProgress()}
							<SpacingView/>
							<SpacingView/>
							{renderExtensionVariants()}
							{renderExtensionVariantSwap()}
							<SpacingView/>
							<SpacingView/>
							<SpacingView/>
							{renderViewButtons()}
						</View>
					)
				}}
				includeErrorMessage
				chainId={0}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	content: {
		maxWidth: 800
	},
	title: {
		fontSize: 18,
		letterSpacing: 0,
		fontWeight: 600,
		fontFamily: 'Source Code Pro'
	},
	gallery: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		opacitiy: 0.1
	},
	description: {
		fontSize: 16,
		letterSpacing: 0,
		fontFamily: 'Source Code Pro'
	},
	textInput: {
		width: 200,
		height: 50,
		fontSize: 16,
		letterSpacing: 0,
		borderWidth: 5,
		fontFamily: 'Source Code Pro',
		padding: 10,
	},
});

function categoriesVariants() {
	let collabBackgrounds = variantData.filter((variant) => {
		return variant.category == "collab"
	})

	let contestWinners = variantData.filter((variant) => {
		return variant.category == "contestWinner"
	})

	let communityBackgrounds = variantData.filter((variant) => {
		return variant.category == "community"
	})

	let result = []

	if (collabBackgrounds.length > 0) {
		result.push({
			description: "",
			name: "Collab Backrounds",
			backgrounds: collabBackgrounds
		})
	}

	result.push({
		description: "",
		name: "Contest Winners",
		backgrounds: contestWinners
	})

	result.push({
		description: "",
		name: "Community Backrounds",
		backgrounds: communityBackgrounds
	})

	return result;
}

const allBackgrounds = categoriesVariants()

export default ThePixelsIncDetail;
