import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, Linking } from "react-native";

import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';

import backgroundHeaderImage from './../../assets/extensions/all_backgrounds.png'

import christmasVariant1 from './../../assets/extensions/christmas_2021_extension_variant_1.gif'
import christmasVariant2 from './../../assets/extensions/christmas_2021_extension_variant_2.gif'
import christmasVariant3 from './../../assets/extensions/christmas_2021_extension_variant_3.gif'

import summer_extension from './../../assets/extensions/summer_extension.png'
import social_extension from './../../assets/extensions/social_extension.png'
import metro_extension from './../../assets/extensions/metroExtension.gif'

function AboutExtensions() {
  return (
    <View>
      <View style={styles.content}>
        <Text style={GlobalStyles.title}>
          {"Extensions"}
        </Text>
        <Text style={GlobalStyles.description}>
          {"Extensions allow owners to add new experiences like traits or backgrounds on your existing 'the pixels inc' without creating new NFTs, in other words it gives the owners the ability to customize their pixels. The extensions you claimed for your pixels are stored on-chain."}
        </Text>
        <SpacingView />
        <SpacingView />

        <Text style={GlobalStyles.title}>
          {"Metro Extension"}
        </Text>
        <SpacingView />
        <Image
          style={styles.summerExtensionImage}
          source={metro_extension}
        />
        <SpacingView />
        <Text style={GlobalStyles.description}>
          {"Our metro extension is here. This extension is dedicated to our first int art drop series the metro collection. "}
          <Text style={GlobalStyles.descriptionLink}
            onPress={() => Linking.openURL('https://drops.int.art/the-metro')}>
            {"'the metro'"}
          </Text>
          {" is collection that has 2048 evolving metro maps, slowly grow with time, just like how a small cities get bigger and bigger with new metro lines. It is on-chain, interactive, evolving in unpredictable way completely on/in-chain. Have your the metro token ready to extend your pixel with randomly picked one of the three metro themed backgrounds."}
        </Text>
        <SpacingView />
        <SpacingView />
        <SpacingView />


        <Text style={GlobalStyles.title}>
          {"Social Extension"}
        </Text>
        <SpacingView />
        <Image
          style={styles.summerExtensionImage}
          source={social_extension}
        />
        <SpacingView />
        <Text style={GlobalStyles.description}>
          {"Our social extension is one of the most innovative extensions we have created yet. Now our pixels can to send each other messages, read their own messages and keep the messages visible on their pixels. What’s even better is that all the messages are on-chain. Can we say this is the first on-chain social messaging in NFTs? We believe so."}
        </Text>
        <SpacingView />
        <SpacingView />
        <SpacingView />

        <Text style={GlobalStyles.title}>
          {"Summer 2022 Extension"}
        </Text>
        <SpacingView />
        <Image
          style={styles.summerExtensionImage}
          source={summer_extension}
        />
        <SpacingView />
        <Text style={GlobalStyles.description}>
          {"Summer extension is our newest extension created specifically for summer occasion. As we are proud to have a contract that enables us to add new traits and backgrounds to our existing pixels, with this summer extension we take our pixels to a vacation. The extension has 3 animated backgrounds and 2 new traits. Some combinations are more rare than the others."}
        </Text>
        <SpacingView />
        <SpacingView />
        <SpacingView />

        <Text style={GlobalStyles.title}>
          {"Background Extension"}
        </Text>
        <SpacingView />
        <Image
          style={[styles.backgroundExtensionImage, {
            marginRight: 20,
            marginBottom: 10
          }]}
          source={backgroundHeaderImage}
        />
        <SpacingView />
        <Text style={GlobalStyles.description}>
          {"We recently organised a community art contest for our background extension. 81 backgrounds were submitted. 5 winners were picked and rewarded from our DAO fund. All 81 community backgrounds are claimable by “the pixels inc” owners. Soon official collab backgrounds representing some of the friends we have made along the way, will be released as well."}
        </Text>
        <SpacingView />
        <SpacingView />
        <SpacingView />
        <Text style={GlobalStyles.title}>
          {"Christmas 2021 Extension"}
        </Text>
        <Text style={GlobalStyles.description}>
          {"We released Christmas 2021 extension in December 2021. It was the proof of concept for our extensions tech behind the pixels. There are 3 different Christmas traits with different rarity."}
        </Text>
        <View style={styles.exampleContainer}>
          <Image
            style={[styles.exampleImage, {
              marginRight: 20,
              marginBottom: 10
            }]}
            source={christmasVariant1}
          />
          <Image
            style={[styles.exampleImage, {
              marginRight: 20,
              marginBottom: 10,
            }]}
            source={christmasVariant2}
          />
          <Image
            style={styles.exampleImage}
            source={christmasVariant3}
          />
        </View>
      </View>
      <Text style={GlobalStyles.description}>
        {"Christmas 2021 extension claiming has now ended. It was released for free and variants were distributed randomly. Don't worry, there will be more extensions in near future!"}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: "grid"
  },
  exampleContainer: {
    marginTop: 10,
    flex: 1,
    flexShrink: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    width: "auto",
    minWidth: 50,
  },
  exampleImage: {
    width: 315,
    height: 315,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  summerExtensionImage: {
    width: 1000,
    height: 350,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  socialExtensionImage: {
    width: 1000,
    height: 350,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  backgroundExtensionImage: {
    width: 1000,
    height: 800,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default AboutExtensions;
