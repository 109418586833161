import theDude from "./abis/TheDude.json";
import theDuds from "./abis/TheDuds.json";
import theAbstractDudes from "./abis/TheAbstractDudes.json";
import theDummyDudes from "./abis/TheDummyDudes.json";
import theDudesStaking from "./abis/TheDudesStaking.json";

import thePixels from "./abis/ThePixels.json";
import thePixelsSale from "./abis/ThePixelsSale.json";

import theDummyPixels from "./abis/TheDummyPixels.json";
import theChristmasExtension from "./abis/TheChristmasExtension.json";

import theDudesPassiveTokenRewarder from "./abis/TheDudesPassiveTokenRewarder.json";
import dummyERC721 from "./abis/DummyERC721.json";
import dummyERC20 from "./abis/DummyERC20.json";
import coreRewarder from "./abis/CoreRewarder.json"

import thePixelsIncExtensionStorage from "./abis/ThePixelsIncExtensionStorage.json"
import thePixelsIncMetadataProvider from "./abis/ThePixelsIncMetadataProvider.json"

import thePixelsIncSummerExtension from "./abis/ThePixelsIncSummerExtension.json"

import thePixelsIncSocialMessageExtension from './abis/ThePixelsIncSocialMessageExtension.json'
import splatWithINT from './abis/SplatWithINT.json'
import theMetro from './abis/TheMetro.json'
import thePixelsIncMetroExtension from './abis/ThePixelsIncMetroExtension.json'

const abis = {
  TheDude: theDude,
  TheDuds: theDuds,
  TheAbstractDudes: theAbstractDudes,
  TheDummyDudes: theDummyDudes,
  TheDudesStaking: theDudesStaking,
  ThePixels: thePixels,
  ThePixelsSale: thePixelsSale,
  TheDummyPixels: theDummyPixels,
  TheChristmasExtension: theChristmasExtension,
  TheDudesPassiveTokenRewarder: theDudesPassiveTokenRewarder,
  CoreRewarder: coreRewarder,
  DummyERC721: dummyERC721,
  DummyERC20: dummyERC20,
  ThePixelsIncExtensionStorage: thePixelsIncExtensionStorage,
  ThePixelsIncMetadataProvider: thePixelsIncMetadataProvider,
  ThePixelsIncSummerExtension: thePixelsIncSummerExtension,
  ThePixelsIncSocialMessageExtension: thePixelsIncSocialMessageExtension,
  SplatWithINT: splatWithINT,
  TheMetro: theMetro,
  ThePixelsIncMetroExtension: thePixelsIncMetroExtension
};

export default abis;
