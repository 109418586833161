import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import SpacingView from '../../components/SpacingView';
import CTAButton from '../../components/CTAButton';
import ProviderAwareView from '../../components/ProviderAwareView';
import GlobalStyles from '../../styles/GlobalStyles';

async function claimReward(provider, address, tokens, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const ThePixelsSaleContract = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return ThePixelsSaleContract.methods.claim(tokens).send({
      from: selectedAccount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
    .on('error', (error, receipt) => {
      onError()
    })
    .on('transactionHash', () => {
      onStarted()
    })
    .then((data) => {
      onFinished()
    })
  }else{
    onError("Please connect your wallet first!")
  }
}

async function getTokens(provider, address) {
  if (typeof provider !== 'undefined') {

    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.tokensOfOwner(selectedAccount).call()
  }
}

async function getStakedTokens(provider, address) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.stakedTokensOfOwner(selectedAccount).call()
  }
}

async function getStakingBoostRate(provider, address) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address)

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.boostRate().call()
  }
}

async function getRewardRate(provider, address) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.rewardRate().call()
  }
}

async function getRewarFrequency(provider, address) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.rewardFrequency().call()
  }
}

async function getRewardFormatted(provider, address, tokens) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    const reward = await TheDudesPassiveTokenRewarder.methods.earned(tokens).call()
    return web3.utils.fromWei(reward)
  }
}

async function getIsEnabled(provider, address, tokens) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const TheDudesPassiveTokenRewarder = new web3.eth.Contract(abis.CoreRewarder, address);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return await TheDudesPassiveTokenRewarder.methods.isClaimingEnabled().call()
  }
}

const calculateRewardRate = (tokens, stakedTokens, rewardRate, stakingBoostRate) => {
  const a = stakedTokens.length * rewardRate * stakingBoostRate
  const b = (tokens.length - stakedTokens.length) * rewardRate
  return a + b;
}

function RewardsClaim({provider, useGlobalState, address, name}) {
  const [isEnabled, setIsEnabled] = useState(undefined);
  const [rewardFormatted, setRewardFormatted] = useState(0);
  const [tokens, setTokens] = useState(undefined);
  const [stakedTokens, setStakedTokens] = useState(undefined);
  const [stakingBoostRate, setStakingBoostRate] = useState(undefined);

  const [isCTADisabled, setIsCTADisabled] = useState(false);
  const [warning, setWarning] = useState(null);
  const [walletState, setWalletState] = useGlobalState("walletState")

  const [formattedRewardRate, setFormattedRewardRate] = useState(0)
    
  const BN = Web3.utils.BN;

  const refreshState = async () => {
    const _isEnabled = await getIsEnabled(provider, address)
    setIsEnabled(_isEnabled)

    const _tokens = await getTokens(provider, address);
    if (!_tokens) {
      return
    }
    const _rewardFormatted = await getRewardFormatted(provider, address, _tokens);
    const _rewardRate = await getRewardRate(provider, address)
    const _rewardFrequency = await getRewarFrequency(provider, address)
    const _stakedTokens = await getStakedTokens(provider, address)
    
    if (_stakedTokens.length > 0) {
      const _stakingBoostRate = await getStakingBoostRate(provider, address)

      setFormattedRewardRate(calculateRewardRate(_tokens, _stakedTokens, _rewardRate, _stakingBoostRate))
      setStakingBoostRate(_stakingBoostRate)
    }else{
      setFormattedRewardRate(calculateRewardRate(_tokens, [], _rewardRate, 0))
    }

    setTokens(_tokens);
    setRewardFormatted(_rewardFormatted);
    setStakedTokens(_stakedTokens)

    if (_rewardFormatted == "0") {
      setIsCTADisabled(true)
    }
  }

  useEffect(() => {
    refreshState()
  }, [provider])

  const renderNoTokens = () => {
    return (
      <View>
        <Text style={GlobalStyles.description}>
          {"You don't have tokens, so no rewards"}
        </Text>
      </View>
    )
  }

  const renderClaim = (rewardFormatted) => {
    const title = isEnabled ? "claim" : "claiming not enabled"
    return (
      <View>
        <CTAButton
          onPress={() => performClaim() }
          title = {title}
          disabled= {isCTADisabled || !isEnabled}
        />
      </View>
    )
  }

  const renderStakedTokens = (stakedTokens) => {
    if (stakedTokens && stakingBoostRate && stakedTokens.length > 0 ) {
      return (
        <View>
          <Text style={GlobalStyles.description}>
            {"Staked tokens: " + stakedTokens.length}
          </Text>
        </View>
      )
    }else{
      return (<View/>)
    }
  }

  const performClaim = async () => {
    setWarning(null)

    claimReward(provider, address, tokens,
    () => {
      setWarning("Claiming... Hold on!")
      setIsCTADisabled(true)
    },
    () => {
      setWarning("Opps, something went wrong!")
      setIsCTADisabled(false)
    }
    ,
    () => {
      setWarning("You got it!")
      setIsCTADisabled(true)
      refreshState()
      setWalletState(walletState + 1)
    })
  }

  const renderSuccess = () => {
    return (
      <View>
        <View>
          <Text style={GlobalStyles.description}>
            {"Total tokens you own: " + tokens.length}
          </Text>
        </View>
        <View>
          <Text style={GlobalStyles.description}>
            {"Reward: " + formattedRewardRate + " $INT per day"}
          </Text>
        </View>
        {renderStakedTokens(stakedTokens)}
        <View>
          <Text style={GlobalStyles.description}>
            {"Total $INT you can claim: " + rewardFormatted}
          </Text>
        </View>
        <SpacingView/>
        {renderClaim(rewardFormatted)}
        <SpacingView/>
        <Text>
            {warning}
        </Text>
      </View>
    )
  }

  return (
    <View>
      <Text style={GlobalStyles.title}>
        {"Claim rewards for '" + name + "'"}
      </Text>
      <ProviderAwareView
        provider={provider}
        render = {() => {
          if (tokens && tokens.length > 0) {
            return renderSuccess()
          }else if (tokens && tokens.length == 0) {
            return renderNoTokens()
          }
        }}
        includeErrorMessage
        chainId={0}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: 250,
    height: 250,
    borderWidth: 3,
    bborderColor: "0x000000"
  }
});

export default RewardsClaim;
