import React, { useState, useEffect } from 'react';
import { Button, Image, StyleSheet, Text, TextInput, View } from "react-native";
import Web3 from 'web3';

import SpacingView from './SpacingView'
import GlobalStyles from '../styles/GlobalStyles'

async function getChainId(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    return web3.eth.getChainId()
  }
}

function ProviderAwareView({ provider, chainId, render, renderError, includeErrorMessage }) {
  const connectingChainId = chainId == undefined ? 1 : chainId
  const [isConnected, setIsConnected] = useState(0)
  const [warning, setWarning] = useState(null);
  const [providerErrorTimer, setProviderErrorTimer] = useState(null);

  useEffect(() => {
    if (provider) {
      getChainId(provider).then(async chainId => {
        if (connectingChainId != 0 && chainId != connectingChainId) {
          setIsConnected(-1)
          setWarning("Seems like you are not connected to correct chain!")
        }else{
          setIsConnected(1)
        }
      })
      clearTimeout(providerErrorTimer)
    }else{
       setProviderErrorTimer(setTimeout(() => {
        setIsConnected(-1)
        setWarning("Please connect your wallet to continue!")
      }, 100))
    }
  }, [provider])


  const renderPage = () => {
    if (isConnected == 1) {
      return (
        <View>
          {render()}
        </View>
      )
    }else if(isConnected == -1) {
      if (renderError) {
        if (includeErrorMessage) {
          return (
            <View>
              <Text style={GlobalStyles.description}>
                {warning}
              </Text>
              <SpacingView/>
              {renderError(includeErrorMessage)}
            </View>
          );
        }else{
          return (
            <View>
              {renderError(includeErrorMessage)}
            </View>
          );
        }
      }else{
        return (
          <View>
            <Text style={GlobalStyles.description}>
              {warning}
            </Text>
          </View>
        )
      }
    }else {
      return
    }
  }

  return (
    <View>
      {renderPage()}
    </View>
  );
}

const styles = StyleSheet.create({
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default ProviderAwareView;
