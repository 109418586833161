import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import MyDude from './MyDude';
import SpacingView from './../components/SpacingView';
import ProviderAwareView from './../components/ProviderAwareView';

async function loadDudes() {
  var web3 = window.web3;
  if (typeof web3 !== 'undefined') {
    web3 = new Web3(web3.currentProvider);
    const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(web3.currentProvider).TheDudesRewarder)

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.tokensOfOwner(selectedAccount).call()
  }
}

function MyDudes({provider}) {
  const [count, setCount] = useState(0)
  const [description, setDescription] = useState(null)
  const [tokenIndexes, setTokenIndexes] = useState(null)

  useEffect(() => {
    loadDudes().then(data => {
      if (data) {
        data = data.slice(0, 30);
        setCount(data.length)
        setTokenIndexes(data)
      }
    })
  }, [provider])

  return (
    <ProviderAwareView provider={provider}
      render = {() => {
        if (tokenIndexes && tokenIndexes.length == 0) {
          return (
            <View>
              <Text style={styles.description}>
                {"Uhhh, you don't have any dudes."}
              </Text>
            </View>
          )
        }else if (tokenIndexes) {
          return (
            <View style={styles.gallery}>
            {tokenIndexes.map((item) => {
              return (
                <MyDude tokenIndex = {item} />
              )
            })}
            </View>
          )
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
  seperator: {
    width: 20,
    height: 20
  }
});

export default MyDudes;
