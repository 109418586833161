import React, { useState, useEffect } from 'react';
import { View, Image, Text, StyleSheet } from "react-native";
import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import StakeTokenButton from './StakeTokenButton';
import CTAButton from './CTAButton';

function ExtensionOrder({ onPress, order, provider, title, onRemove }) {
  const [imageURL, setImageURL] = useState(null)
  const [name, setName] = useState(null)

  const price = order.visualPrice ? Web3.utils.fromWei(order.visualPrice) : order.extension.price 

  const renderCollectionToken = () => {
    if (order.useCollectionToken && order.collectionTokenId) {
      return (
        <View style={styles.plusTextContainer}>
            <Text style={styles.usedTokenText}>{"Used tokenId: #" + order.collectionTokenId}</Text>
          </View>
      )
    }
  }
  return (
    <View style={{
      marginRight: 10
    }}>
      <View style={{
      flexDirection: "row",
      justifyContent: "space-between"
    }}>
      <View style={styles.leftContainer}>
        <StakeTokenButton
          title={"#" + order.tokenId}
          provider={provider}
          contractAbi={abis.ThePixels}
          contractAddress={addressesByNetwork(provider).ThePixels}
          tokenId={order.tokenId}
          key={order.tokenId}
          selected ={true}
        />
        <View style={styles.plusTextContainer}>
          <Text style={styles.plusText}>{" + "}</Text>
        </View>
        <Image
          style = {styles.imageSelected}
          source = {order.extension.image_520x520}
          key={Date.now()}
        />
        {renderCollectionToken()}
      </View>
      <View style={styles.rightContainer}>
        <View style={styles.plusTextContainer}>
          <Text style={styles.priceText}>{price + " $INT"}</Text>
        </View>
        <View style={styles.removeButtonContainer}>
          <CTAButton
                onPress={() => {
                  if (onRemove) {
                    onRemove(order)
                  }
                }}
                title="x"
                type="x"
              />
        </View>
      </View>

    </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 15,
    elevation: 3,
    backgroundColor: 'black',
    height: '30px',
    marginRight: '10px',
    marginBottom: '5px'
  },
  textLight: {
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'gray',
    fontFamily: 'Source Code Pro'
  },
  textDark: {
    fontSize: 13,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0
  },
  imageContainer: {
    width: 120,
    height: 120,
  },
  plusTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginRight: 10
  },
  removeButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15
  },
  plusText: {
    fontSize: 30,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
  priceText: {
    fontSize: 24,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
  usedTokenText: {
    fontSize: 18,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
  leftContainer: {
    flexDirection: "row",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: 'flex-end'
  },
  imageSelected: {
    width: 120,
    height: 120,
    borderWidth: 3,
    bborderColor: "0x000000",
    marginRight: 5,
    marginTop: 15
  },
});

export default ExtensionOrder
