import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import Unstake from './Unstake';
import SpacingView from '../../components/SpacingView';
import GlobalStyles from '../../styles/GlobalStyles';

function UnstakingAll({provider}) {
  return (
    <View>
        <Unstake
            provider = {provider}
            address = {addressesByNetwork(provider).ThePixelsIncRewarder}
            nftAddress = {addressesByNetwork(provider).ThePixels}
            name = "the pixels inc"
        />
        <SpacingView/>
        <SpacingView/>
        <SpacingView/>
        <Unstake
            provider = {provider}
            address = {addressesByNetwork(provider).TheDudesRewarder}
            nftAddress = {addressesByNetwork(provider).TheDude}
            name = "the dudes"
        />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default UnstakingAll;
