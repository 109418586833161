import React from "react";
import { Pressable, Text, StyleSheet } from "react-native";

function CTAButton({ onPress, title, disabled, type}) {
  return (
    <Pressable
      style={()=> {
        if(type == "small") {
          return styles.small
        }else if (type == "x") {
          return styles.x
        }else{
          return styles.default
        }
      }}
      onPress={disabled ? ()=> {} : onPress}
    >
      <Text style={disabled ? styles.textDark : styles.textLight}>{title}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  x: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 5,
    paddingTop: 8,
    paddingBottom: 11,
    elevation: 3,
    backgroundColor: 'black',
    height: '20px',
  },
  small: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    elevation: 3,
    backgroundColor: 'black',
    height: '55px',
    marginRight: '10px',
    marginBottom: '5px'
  },
  default: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    elevation: 3,
    backgroundColor: 'black',
    height: '55px',
    width: '320px',
    marginRight: '10px',
    marginBottom: '5px'
  },
  textLight: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: 'white',
    fontFamily: 'Source Code Pro'
  },
  textDark: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 0.25,
    color: 'grey',
    fontFamily: 'Source Code Pro'
  },
});

export default CTAButton
