import React from "react";
import { Pressable, Text, StyleSheet } from "react-native";
import { useHistory } from "react-router-dom";

import CTAButton from "./CTAButton";

function NavigationButton({ title, to }) {
  let history = useHistory();

  const onPress = () => {
    history.push(to);
  }

  return (
    <CTAButton
      title = {title}
      onPress = {onPress}
      type = "small"
    />
  );
}

export default NavigationButton
