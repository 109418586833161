import React, { useState, useEffect } from 'react';
import { TextInput, Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import GlobalStyles from '../../../styles/GlobalStyles';
import Checkbox from '../../../components/Checkbox'

function SocialExtensionMessage({message, onChange}) {
    return (
        <View style={styles.container}>
            <View style={styles.messageContainer}>
                <Text style={styles.messageTitle}>{"Message: "}</Text>
                <Text style={GlobalStyles.description}>{message.message}</Text>
                <SpacingView/>
                <SpacingView/>
                <Text>{"Sender Token ID: #" + message.senderTokenId}</Text>
            </View>
            <View style={styles.rightViewContainer}>
                <Checkbox
                    onValueChange={ (newValue) => {
                        message.isHidden = newValue
                        onChange()
                    }}
                    isSelected={message.isHidden}
                    title="hidden: "
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
        flexDirection: "row",
        backgroundColor: "#e8e8e8",
        alignSelf: 'flex-start'
    },
    title: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    description: {
        width: 600,
        fontSize: 16,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro'
    },
    tokenIdContainer: {
        flex: 1,
        flexDirection: "row"
    },
    messageTitle: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    messageContainer: {
        width: 400
    },
    rightViewContainer: {
        alignSelf: "center",
        marginRight: 10
    }
});

export default SocialExtensionMessage;
