import React, { useState, useEffect } from 'react';
import { View, Pressable,Image, Text, StyleSheet } from "react-native";

function ExtensionButton({ onPress, variant, selected, canSelect}) {
  const renderClaimStatus = () => {
    if (variant.isDisabled) {
      return (
        <Text style={selected ? styles.textClaimDark : styles.textClaimLight}>{"claiming disabled"}</Text>
      )
    }else if (variant.isClaimed) {
      return (
        <Text style={selected ? styles.textClaimDark : styles.textClaimLight}>{"already claimed"}</Text>
      )
    }else if (variant.supply != 0 && variant.count == variant.supply) {
      return (
        <Text style={selected ? styles.textClaimDark : styles.textClaimLight}>{"sold out"}</Text>
      )
    }else{
      return (
        <Text style={selected ? styles.textClaimDark : styles.textClaimLight}>{"available to claim"}</Text>
      )
    }
  }

  const renderFooterTexts = () => {
    if(variant.count && variant.supply) {
      return (
        <Text style={selected ? styles.textPriceDark : styles.textPriceLight}>{" - " + variant.count + "/" + variant.supply}</Text>
      )
    }
  }

  const select = () => {
    if (canSelect) {
      onPress()
    }
  }

  return (
    <Pressable
      onPress={select}
    >
      {/* <Text style={selected ? styles.textDark : styles.textLight}>{variant.name}</Text> */}
      <Text style={selected ? styles.textDark : styles.textLight}>{variant.name}</Text>
      {renderClaimStatus()}
      <Image
        style = {selected? styles.imageSelected : styles.image}
        source = {variant.image_520x520}
        key={variant.variantId}
      />
      <View style={styles.footerTexts}>
        <Text style={selected ? styles.textPriceDark : styles.textPriceLight}>
          {(variant.contractPrice ? variant.contractPrice : variant.price) + " $INT"}
          {renderFooterTexts()}
        </Text>
      </View>
      
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 15,
    elevation: 3,
    backgroundColor: 'black',
    height: '30px',
    marginRight: '10px',
    marginBottom: '5px'
  },
  textLight: {
    fontSize: 15,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'gray',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },
  textDark: {
    fontSize: 15,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
  },

  textPriceLight: {
    fontSize: 18,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'gray',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
    marginBottom: 20
  },
  textPriceDark: {
    fontSize: 18,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
    marginBottom: 20
  },

  textClaimLight: {
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'gray',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
    marginBottom: 5
  },
  textClaimDark: {
    fontSize: 10,
    lineHeight: 15,
    letterSpacing: 0.25,
    color: 'black',
    fontFamily: 'Source Code Pro',
    letterSpacing: 0,
    marginBottom: 5
  },


  imageContainer: {
    width: 120,
    height: 120,
  },
  image: {
    width: 185,
    height: 185,
    borderWidth: 3,
    bborderColor: "0x000000",
    marginRight: 10,
    opacity: 0.5,
    marginBottom: 5
  },
  imageSelected: {
    width: 185,
    height: 185,
    borderWidth: 5,
    bborderColor: "0x000000",
    marginRight: 10,
    marginBottom: 5
  },
  footerTexts: {
    marginBottom: 10
  }
});

export default ExtensionButton
