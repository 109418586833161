const addresses = {
  // TheDude: "0xb0cf7da8dc482997525be8488b9cad4f44315422",
  TheDuds: "0x427c9e3af704a1b8f0576e5d4aedbb90b177a86d",
  TheAbstractDudes: "0x0808a6fa1e49220478b6dcc866cb9104b9bacf72",

  ThePixelsSale:  "0x9CbbEB498eA00790ffF4D32b47232b180f6bCfEf",
  ThiePixelsMetadata: "0x8216B70a3AC84f14549DaFB19cDdC55a849b5Dd5",

  TheChristmasExtension: "0x01f2B560401DfE99FB5AA5F093Faf006531d43a4",

  TheDummyPixelsInc: "0xa55a4b8e8e9c2723301a468a5ea805fac181037b",
  TheDummyDudes: "0x9649a41947a5142642efc201c2eb064fc2a90f0d",

  ThePixels:      "0x7Ff2a00Ff543f913b76010A05b5446E36D403675",
  ThePixelsIncRewarder: "0x2228b2a09ed31AEDBF28172e79Dc89E681504E98",
  TheDudesRewarder: "0x4FD44d0C6f0D81Cdc3B6F4b13d174a2a35b61baB",
  INT: "0xf011DaC919a751dfF6343553aA031B9f8E95300F",
  TheDude: "0xb0cf7da8dc482997525be8488b9cad4f44315422",

  ThePixelsIncExtensionStorage: "0x38df9910Df3dd3b0F58795e98d5cE68ca57A1935"
};

const addressesWithChainId = {
  "0x1": {
    ThePixels:      "0x7Ff2a00Ff543f913b76010A05b5446E36D403675",
    ThePixelsIncRewarder: "0x2228b2a09ed31AEDBF28172e79Dc89E681504E98",
    TheDudesRewarder: "0x4FD44d0C6f0D81Cdc3B6F4b13d174a2a35b61baB",
    INT: "0xf011DaC919a751dfF6343553aA031B9f8E95300F",
    TheDude: "0xb0cf7da8dc482997525be8488b9cad4f44315422",
    ThePixelsIncExtensionStorage: "0x82627768d439e13ed58c447a77941415921803da",
    ThePixelsIncMetadataProvider: "0x6bB2bCf506b8755268B10030c6f490B162b84ad9",
    ThePixelsIncSummerExtension: "0x4e2081F0F6c4c2d08Ce1f5888D8Eb73783CaA271",
    ThePixelsIncSocialMessageExtension: "0x1b72677a2163f4d4479B7F2A8A4F6f839142860f",
    SplatWithINT: "0xBd479d162dCCF08669ba9a9483fec55E1FA4105e",
    Splat: "0x30535831e3244dc15153ce173c2803af4ee7e374",
    TheMetro: "0xca24e7d9e8a2ba3ada22383f5e2ad397b5677e25",
    ThePixelsIncMetroExtension: "0xB169BA460b72034c7b82798De3a1c2c7d1b06aC5"
  },

  "0x4": {
    ThePixels:      "0x7C7De3F02308dA2a3265551b446D442751F83ab1",
    ThePixelsIncRewarder: "0x39De76095Ad45C63d67cB02Abb07DE6a86dbaEf0",
    TheDudesRewarder: "0xC55EEBDF3c91c2cF6F50E7d6dF73d4bbea17FAe1",
    INT: "0xe311b99E0b21454e8659DF783BE6F0b124a2c968",
    TheDude: "0x9649a41947a5142642efc201c2eb064fc2a90f0d",
    ThePixelsIncExtensionStorage: "0x82627768d439e13ed58c447a77941415921803da",
    ThePixelsIncMetadataProvider: "0x6bB2bCf506b8755268B10030c6f490B162b84ad9",
    ThePixelsIncSummerExtension: "0x4e2081F0F6c4c2d08Ce1f5888D8Eb73783CaA271",
    ThePixelsIncSocialMessageExtension: ""
  },

  "0x5": {
    ThePixels:      "0xEdfd7c66c37Fa61C6836B3679B338d72D184e722",
    ThePixelsIncRewarder: "0x4B25C4Bcf198ef5eaF3b492627740b5e5f802ae5",
    TheDudesRewarder: "0xCB2B69271ED724E5c23C1e3C391874Ea818E26B0",
    INT: "0x0Ff92d4e90f36DCa02207F6AdeC45c14701B0230",
    TheDude: "0x20AF4Ec6a74fe8ffb990f69ba64B1D51D564f5A1",
    ThePixelsIncExtensionStorage: "0xd5e9485475aa8cb16C112c7132171C20492d18dB",
    ThePixelsIncMetadataProvider: "0x6bB2bCf506b8755268B10030c6f490B162b84ad9",
    ThePixelsIncSummerExtension: "0x4e2081F0F6c4c2d08Ce1f5888D8Eb73783CaA271",
    ThePixelsIncSocialMessageExtension: "0x6a7dC471dA4140FCA85Fd723FbD6F79e758B0a4E"
  },

  "0x7a69": {
    ThePixels:      "0x7Ff2a00Ff543f913b76010A05b5446E36D403675",
    ThePixelsIncRewarder: "0x2228b2a09ed31AEDBF28172e79Dc89E681504E98",
    TheDudesRewarder: "0x4FD44d0C6f0D81Cdc3B6F4b13d174a2a35b61baB",
    INT: "0xf011DaC919a751dfF6343553aA031B9f8E95300F",
    TheDude: "0xb0cf7da8dc482997525be8488b9cad4f44315422",
    ThePixelsIncExtensionStorage: "0x82627768d439e13ed58c447a77941415921803da",
    ThePixelsIncMetadataProvider: "0x6bB2bCf506b8755268B10030c6f490B162b84ad9",
    ThePixelsIncSummerExtension: "0x4e2081F0F6c4c2d08Ce1f5888D8Eb73783CaA271",
    ThePixelsIncSocialMessageExtension: "0x1b72677a2163f4d4479B7F2A8A4F6f839142860f",
    SplatWithINT: "0xBd479d162dCCF08669ba9a9483fec55E1FA4105e",
    Splat: "0x30535831e3244dc15153ce173c2803af4ee7e374",
    TheMetro: "0xca24e7d9e8a2ba3ada22383f5e2ad397b5677e25",
    ThePixelsIncMetroExtension: "0xB169BA460b72034c7b82798De3a1c2c7d1b06aC5"
  }
}

const addressesByNetwork = (provider) => {
  if(provider && provider.chainId) {
    return addressesWithChainId[provider.chainId]
  }
  return addressesWithChainId["0x1"]
}

export {addresses, addressesByNetwork};
