import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from './../../components/CTAButton';
import SpacingView from './../../components/SpacingView';
import ProviderAwareView from './../../components/ProviderAwareView';
import StakeTokenButton from './../../components/StakeTokenButton';
import GlobalStyles from '../../styles/GlobalStyles';

async function getExtensionState(provider, state) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.TheChristmasExtension, addresses.TheChristmasExtension);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return Contract.methods.isLive().call()
  }
}

async function getPixelsOfOwner(provider) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixels, addresses.ThePixels);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return Contract.methods.tokensOfOwner(selectedAccount).call()
    }
  }
}

async function getTokenStatus(provider, tokens) {
  if (typeof provider !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.TheChristmasExtension, addresses.TheChristmasExtension);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];
    if (selectedAccount) {
      return Contract.methods.getExtendStatusOf(tokens).call()
    }
  }
}

async function extendPixel(provider, tokenId, onStarted, onError, onFinished) {
  if (typeof web3 !== 'undefined') {
    const web3 = new Web3(provider);
    const Contract = new web3.eth.Contract(abis.ThePixels, addresses.ThePixels);

    const accounts = await web3.eth.getAccounts();
    const selectedAccount = accounts[0];

    return Contract.methods.updateDNAExtension(tokenId).send({
      from: selectedAccount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
    .on('error', (error, receipt) => {
      console.log("error");
      onError()
    })
    .on('transactionHash', () => {
      onStarted()
    })
    .then((data) => {
      onFinished()
    })
  }else{
    onError("Please connect your wallet first!")
  }
}

function TheChristmas2020Extension({provider}) {
  const [warning, setWarning] = useState(null);
  const [isLive, setIsLive] = useState(null)
  const [tokenIndexes, setTokenIndexes] = useState(null)
  const [extendedTokens, setExtendedTokens] = useState(null)
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);
  const [selectedTokenIndexes, setSelectedTokenIndexes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const refresh = () => {
    setWarning(null)
    getExtensionState(provider).then(data => {
      if (!data) {
        setIsLive(false)
        return
      }
      setIsLive(true)
      getPixelsOfOwner(provider).then(async tokens => {
        if (!tokens) {
          return
        }
        getTokenStatus(provider, tokens).then(async status => {
          if (!status) {
            return
          }
          let alreadyExtendedTokens = []
          const filteredTokens = tokens.filter((item, index) => {
            const itemInt = parseInt(item)
            if (itemInt == 5057 || itemInt == 5058 || itemInt == 5059 || itemInt == 5060 || itemInt == 5061) {
              return false
            }
            if (!status[index]) {
              alreadyExtendedTokens.push(item)
              return false
            }
            return true
          })
          console.log("status", tokens, status);
          setTokenIndexes(filteredTokens)
          setExtendedTokens(alreadyExtendedTokens)
        })
      })
    })
  }

  const chooseToken = (tokenIndex) => {
    if (isTokenSelected(tokenIndex)) {
      const currentTokens = selectedTokenIndexes.filter((item) => {
        if (item == tokenIndex) {
          return false
        }
        return true
      })
      setSelectedTokenIndexes(currentTokens)
    }else{
      setSelectedTokenIndexes([tokenIndex])
    }
  }

  const isTokenSelected = (index) => {
    for(let i=0; i<selectedTokenIndexes.length; i++) {
      if (index == selectedTokenIndexes[i]) {
        return true
      }
    }
    return false
  }

  const performExtend = (index) => {
    console.log("index", index);
    setWarning(null)
    setIsDisabled(true)
    extendPixel(provider, index,
    () => {
      setWarning("Extending, hold on!")
      setIsDisabled(false)
    },
    () => {
      setWarning("Opps, something went wrong dude!")
      setIsDisabled(false)
    }
    ,
    () => {
      setWarning("You pixel is extended!!")
      setIsDisabled(false)
      refresh()
    })
  }

  useEffect(() => {
    refresh()
  }, [provider])

  const renderExtensionNotActive = () => {
    return (
      <View>
        <SpacingView/>
        <SpacingView/>
        <Text style = {GlobalStyles.title}>This extension is ended :( Please stay tuned for more extensions!</Text>
      </View>
    )
  }

  const open = (tokenId) => {
    const url = "https://opensea.io/assets/" + addresses.ThePixels + "/" + tokenId
    window.open(url)
  }

  const renderAlreadyExtendedSection = () => {
    return (
      <View>
        {(() => {
          if(extendedTokens && extendedTokens.length > 0) {
            return (
              <View>
                <Text style = {GlobalStyles.title}>Your extended pixels:</Text>
                <SpacingView/>
                <View style={styles.gallery}>
                  {extendedTokens.map((item) => {
                    return (
                      <StakeTokenButton
                        key = {item}
                        title = {"#" + item}
                        provider = {provider}
                        contractAbi = {abis.ThePixels}
                        contractAddress = {addresses.ThePixels}
                        selected = {true}
                        tokenId = {item}
                        onPress={() => {
                          open(item)
                        }}
                      />
                    )
                  })}
                </View>
              </View>
            )
          }
        })()}
      </View>
    )
  }

  const renderAvailablePixelsSection = () => {
    return (
      <View>
        <SpacingView/>
        <SpacingView/>
        {(() => {
          if(tokenIndexes && tokenIndexes.length > 0) {
            return (
              <View>
                <Text style = {GlobalStyles.title}>Your available pixels to extend:</Text>
                <SpacingView/>
                <View style={styles.gallery}>
                  {tokenIndexes.map((item) => {
                    return (
                      <StakeTokenButton
                        onPress={() => chooseToken(item)}
                        title = {"#" + item}
                        key = {item}
                        selected = {isTokenSelected(item)}
                        provider = {provider}
                        contractAbi = {abis.ThePixels}
                        contractAddress = {addresses.ThePixels}
                        tokenId = {item}
                      />
                    )
                  })}
                </View>
                <SpacingView/>
                <SpacingView/>
                <CTAButton
                  onPress={() => performExtend(selectedTokenIndexes[0])}
                  title = "extend selected"
                  disabled = {isDisabled || selectedTokenIndexes.length == 0}
                />
                <Text>{warning}</Text>
              </View>
            )
          }else if (tokenIndexes) {
            return (
              <View>
                <Text style = {GlobalStyles.description}>Sorry... You don't have any pixels to extend.</Text>
              </View>
            )
          }
        })()}
      </View>
    )
  }

  const renderExtensionActive = () => {
    return (
      <View>
        {renderAlreadyExtendedSection()}
        {renderAvailablePixelsSection()}
      </View>
    )
  }

  return (
    <View>
      <Text style = {GlobalStyles.title}>Christmas 2021 Extension</Text>
      <Text style = {GlobalStyles.description}>There are 3 items in Christmas collection this year. You will get one item randomly once you extend your pixel. Please choose your pixel from below to extend it.</Text>
      <SpacingView />
			<SpacingView />
			<SpacingView />
      <ProviderAwareView
        provider={provider}
        render = {() => {
          if (!isLive) {
            return renderExtensionNotActive()
          }else{
            return renderExtensionActive()
          }
        }}
        includeErrorMessage
        renderError = {() => {
          return renderExtensionNotActive()
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  description: {
    width: 600,
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default TheChristmas2020Extension;
