import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import { addresses, abis } from "@project/contracts";

import SpacingView from './../components/SpacingView';
import MyDudes from "./MyDudes";
import MyDuds from "./MyDuds";
import MyAbstractDudes from "./MyAbstractDudes";
import MyPixels from "./MyPixels";

function MyCollection({provider}) {
  return (
    <View>
      <Text style={styles.title}>{'My "the pixels inc"'}</Text>
      <SpacingView/>
      <MyPixels provider={provider}/>
      <SpacingView/>
      <Text style={styles.title}>{'My "the dudes"'}</Text>
      <SpacingView/>
      <MyDudes provider={provider}/>
      <SpacingView/>
      <Text style={styles.title}>{'My "the duds"'}</Text>
      <SpacingView/>
      <MyDuds provider={provider}/>
      <SpacingView/>
      <Text style={styles.title}>{'My "the abstract dudes"'}</Text>
      <SpacingView/>
      <MyAbstractDudes provider={provider}/>
      <SpacingView/>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 800
  },
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  }
});

export default MyCollection;
