import React from 'react';
import { StyleSheet, View } from "react-native";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NavigationButton from '../../components/NavigationButton';
import SpacingView from '../../components/SpacingView';

import AboutExtensions from './AboutExtensions.js'
import TheChristmas2020Extension from './TheChristmas2020Extension.js'
import BackgroundExtension from './BackgroundExtension.js'
import Summer2022Extension from './Summer2022Extension.js'
import SocialExtension from './SocialExtension/SocialExtension.js'
import MetroExtension from './MetroExtension';

function AllExtensions({ provider, useGlobalState}) {
  return (
    <View style={styles.content} >
      <View style={[styles.container, {
        flexDirection: "row",
        flexWrap: "wrap",
      }]}>
        <NavigationButton to="/the-pixels-inc-extensions/about" title="about" />
        <NavigationButton to="/the-pixels-inc-extensions/metro" title="metro" />
        <NavigationButton to="/the-pixels-inc-extensions/social" title="social" />
        <NavigationButton to="/the-pixels-inc-extensions/summer-2022" title="summer 2022" />
        <NavigationButton to="/the-pixels-inc-extensions/background" title="background" />
        <NavigationButton to="/the-pixels-inc-extensions/christmas-2021" title="christmas 2021" />
      </View>
      <SpacingView />
      <SpacingView />
      <Switch>
        <Route path="/the-pixels-inc-extensions/about"><AboutExtensions/></Route>
        <Route path="/the-pixels-inc-extensions/metro"><MetroExtension provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/social"><SocialExtension provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/christmas-2021"><TheChristmas2020Extension provider={provider} /></Route>
        <Route path="/the-pixels-inc-extensions/summer-2022"><Summer2022Extension provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions/background"><BackgroundExtension provider={provider} useGlobalState={useGlobalState}/></Route>
        <Route path="/the-pixels-inc-extensions"><AboutExtensions/></Route>
      </Switch>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 1000
  },
  bar: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  title: {
    fontSize: 18,
    letterSpacing: 0,
    fontWeight: 600,
    fontFamily: 'Source Code Pro'
  },
  description: {
    fontSize: 16,
    letterSpacing: 0,
    fontFamily: 'Source Code Pro'
  },
});

export default AllExtensions;
