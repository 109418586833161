import React, { useState, useEffect } from 'react';
import { Pressable, Button, Image, StyleSheet, Text, View, Dimensions, Linking } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	useParams,
	useHistory
  } from "react-router-dom";

import SpacingView from './../components/SpacingView';

async function loadDNA(tokenId) {
  var web3 = window.web3;
  if (typeof web3 !== 'undefined') {
    web3 = new Web3(web3.currentProvider);
    const Contract = new web3.eth.Contract(abis.ThePixelsIncMetadataProvider, addressesByNetwork(web3.currentProvider).ThePixelsIncMetadataProvider);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.fullDNAOfToken(tokenId).call()
  }
}

function MyPixel({tokenIndex}) {
  let history = useHistory();
  const [imageURL, setImageURL] = useState(null)
  const [name, setName] = useState(" ")
  const [dna, setDNA] = useState("")

  const url = "https://opensea.io/assets/" + addresses.ThePixels + "/" + tokenIndex

  useEffect(() => {
    loadDNA(tokenIndex).then(async dna => {
      setDNA(dna)
      setImageURL("https://api.int.art/collections/the-pixels-inc/gif/" + tokenIndex)
      setName("the pixel inc #" + tokenIndex)
    })
  }, [])

  const fethTokenMetada = async (_tokenURI) => {
    let response = await fetch(_tokenURI)
    let json = await response.json()
    return json
  }

  return (
    <View style={styles.container}>
      <Pressable
        onPress={() => {
          history.push("/collections/the-pixels-inc/" + tokenIndex)
        }}>
        <Text style={styles.title}>
          {name}
        </Text>
        <Text style={styles.description}>
          {dna}
        </Text>
        <SpacingView/>
        <Image
          style = {styles.image}
          source = {imageURL}
        />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginRight: 20,
    marginBottom: 20
  },
  imageContainer: {
    width: 250,
    height: 250,
  },
  image: {
    width: 250,
    height: 250,
    borderWidth: 3,
    bborderColor: "0x000000"
  },
  title: {
		fontSize: 16,
		letterSpacing: 0,
		fontWeight: 600,
		fontFamily: 'Source Code Pro'
	},
	description: {
		fontSize: 14,
		letterSpacing: 0,
		fontFamily: 'Source Code Pro'
	},
});

export default MyPixel;
