import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image } from "react-native";
import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import RewardsClaim from './RewardsClaim';
import SpacingView from '../../components/SpacingView';

function RewardsAllClaim({provider, useGlobalState}) {

  return (
    <View>
        <RewardsClaim
            provider = {provider}
            address = {addressesByNetwork(provider).ThePixelsIncRewarder}
            useGlobalState = {useGlobalState}
            name = "the pixels inc"
        />
        <SpacingView/>
        <SpacingView/>
        <RewardsClaim
            provider = {provider}
            address = {addressesByNetwork(provider).TheDudesRewarder}
            useGlobalState = {useGlobalState}
            name = "the dudes"
        />
    </View>
  );
}

export default RewardsAllClaim;
