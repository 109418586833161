import React, { useState, useEffect } from 'react';
import { TextInput, Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import ProviderAwareView from '../../../components/ProviderAwareView';
import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import StakeTokenButton from '../../../components/StakeTokenButton';
import SocialExtensionTargetToken from './SocialExtensionTargetToken';
import GlobalStyles from '../../../styles/GlobalStyles';
import SocialExtensionMessageOrder from './SocialExtensionMessageOrder'

const extensionId = 2

async function sendMessage(provider, senderTokenIds, targetTokenIds, messages, onStarted, onError, onFinished) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        const collectionIds = senderTokenIds.map((v) => { return 0 })

        return Contract.methods.sendMessages(senderTokenIds, targetTokenIds, collectionIds, messages).send({
            from: selectedAccount,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error, receipt) => {
                onError()
            })
            .on('transactionHash', () => {
                onStarted()
            })
            .then((data) => {
                onFinished()
            })
    } else {
        onError("Please connect your wallet first!")
    }
}

async function approveToken(provider, onStarted, onError, onFinished) {
    if (typeof web3 !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        const maxAmount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"

        return Contract.methods.approve(addressesByNetwork(provider).ThePixelsIncSocialMessageExtension, maxAmount).send({
            from: selectedAccount
        })
            .on('error', (error, receipt) => {
                onError()
            })
            .on('transactionHash', () => {
                onStarted()
            })
            .then((data) => {
                onFinished()
            })
    } else {
        onError("Please connect your wallet first!")
    }
}

async function getIsApprovedForAll(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        if (selectedAccount) {
            return Contract.methods.allowance(selectedAccount, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension).call()
        }
    }
}

async function getAllVariants(provider, orders) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        return Contract.methods.variantsOfExtension(1, [1, 2, 3, 4, 5, 6]).call()
    }
}

async function getTokens(provider) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.tokensOfOwner(selectedAccount).call()
    }
}

async function getVariantIdsOfTokens(provider, tokenIds) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.currentVariantIdsOf(2, tokenIds).call()
    }
}

async function getMessagePrice(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);

        const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

        return await Contract.methods.messagePrices(0).call()
    }
}

async function getINTBalance(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);

        const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.balanceOf(selectedAccount).call()
    }
}

async function getExtension(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        if (selectedAccount) {
            return Contract.methods.extensions(extensionId).call()
        }
    }
}

function SocialExtensionSendMessagePixel({ provider, useGlobalState }) {
    const [addedMessages, setAddedMessages] = useState([]);
    const [targetTokenIdDebounce, setTargetTokenIdDebounce] = useState();
    const [targetTokenId, setTargetTokenId] = useState();
    const [targetTokenIdText, setTargetTokenIdText] = useState();
    const [message, setMessage] = useState();
    const [intBalance, setIntBalance] = useState(0);
    const [isEnabled, setIsEnabled] = useState(null)
    const [extendedTokenIndexes, setExtendedTokenIndexes] = useState();
    const [selectedExtendedTokenIndex, setSelectedExtendedTokenIndex] = useState();
    const [totalOrderPrice, setTotalOrderPrice] = useState()
    const [allVariants, setAllVariants] = useState([])
    const [walletState, setWalletState] = useGlobalState("walletState")
    const [isApprovedForAll, setIsApprovedForAll] = useState(false);
    const [messagePrice, setMessagePrice] = useState();
    const [isAddMessageEanbled, setIsAddMessageEanbled] = useState(false);
    const [warning, setWarning] = useState(null);

    useEffect(() => {
        refresh(true)
    }, [provider])

    const hasEnoughBalance = () => {
        if (addedMessages.length > 0) {
            const BN = Web3.utils.BN;
            const totalPrice = new BN(messagePrice).mul(new BN(addedMessages.length))
            const intBalanceBN = new BN(intBalance)

            if (intBalanceBN.gte(totalPrice)) {
                return true;
            }
            return false;
        }
    }

    const reset = () => {
        setAddedMessages([])
        setWarning()
        setIsAddMessageEanbled(false)
        setMessage("")
        setTargetTokenId()
        setTargetTokenIdText("")
    }

    const refreshApproval = async () => {
        const _isApprovedForAll = await getIsApprovedForAll(provider)
        if (_isApprovedForAll && _isApprovedForAll > 0) {
            setIsApprovedForAll(true)
        }
    }

    const refresh = async (refreshSelectedTokenIds) => {
        if (!provider) {
            return
        }

        setAddedMessages([])
        setWarning()
        setIsAddMessageEanbled(false)

        let _extension = await getExtension(provider)

        if (_extension) {
            setIsEnabled(_extension.isEnabled);
            if (_extension.isEnabled) {
                if (refreshSelectedTokenIds) {
                    setSelectedExtendedTokenIndex()
                }
            } else {
                return
            }
        } else {
            return
        }

        await refreshApproval()

        let _allVariants = await getAllVariants(provider)
        setAllVariants(_allVariants)

        let _tokens = await getTokens(provider)
        setIntBalance(await getINTBalance(provider))

        let _messagePrice = await getMessagePrice(provider)
        setMessagePrice(_messagePrice)

        let _extendableTokens = []
        let _extendedTokens = []
        let _variantIds = await getVariantIdsOfTokens(provider, _tokens)

        _variantIds.forEach((variant, index) => {
            if (variant != "0") {
                _extendedTokens.push(_tokens[index])
            } else {
                _extendableTokens.push(_tokens[index])
            }
        })

        setExtendedTokenIndexes(_extendedTokens)
        setWalletState(walletState + 1)
    }

    const chooseExtendedToken = (token) => {
        setWarning()
        setSelectedExtendedTokenIndex(token)
    }

    const isExtendedTokenSelected = (index) => {
        return index == selectedExtendedTokenIndex
    }

    const performSendMessage = () => {
        setWarning(null)

        let senderTokenIds = []
        let targetTokenIds = []
        let messages = []

        addedMessages.forEach(message => {
            senderTokenIds.push(message.senderTokenId.toString())
            targetTokenIds.push(message.targetTokenId.toString())
            messages.push(message.message)
        })

        sendMessage(provider, senderTokenIds, targetTokenIds, messages,
            () => {
                setWarning("Sending, hold on!")
            },
            () => {
                setWarning("Opps, something went wrong!")
            }
            ,
            () => {
                setWarning("You've just sent!")
                reset()
                refresh()
            })
    }

    const performAppprove = () => {
        setWarning(null)
        approveToken(provider,
            () => {
                setWarning("Approving, hold on!")
            },
            () => {
                setWarning("Opps, something went wrong!")
            }
            ,
            () => {
                refreshApproval()
                setWarning("You've just approved!")
            })
    }

    const onTargetTokenIdChange = (tokenId) => {
        setIsAddMessageEanbled(false)
        setTargetTokenIdText(tokenId)
        if (targetTokenIdDebounce) {
            clearInterval(targetTokenIdDebounce)
            setTargetTokenIdDebounce()
        }
        const value = parseInt(tokenId)

        if ((value == 0 || value) && value >= 0 && value <= 5062) {
            const timer = setTimeout(() => {
                setTargetTokenId(value)
                setIsAddMessageEanbled(true)
            }, 1000);
            setTargetTokenIdDebounce(timer)
        } else {
            setTargetTokenId()
            setTargetTokenIdText()
        }
    }

    const addMessage = () => {
        const newAddedMessages = addedMessages.concat([
            {
                senderTokenId: selectedExtendedTokenIndex,
                targetTokenId: targetTokenId,
                message: message
            }
        ])
        setWarning()
        setAddedMessages(newAddedMessages)
    }

    const renderTargetToken = () => {
        if (targetTokenId || targetTokenId == 0) {
            return (
                <SocialExtensionTargetToken
                    title={"#" + targetTokenId}
                    selected={true}
                    provider={provider}
                    contractAbi={abis.ThePixels}
                    contractAddress={addressesByNetwork(provider).ThePixels}
                    tokenId={targetTokenId}
                />
            )
        }
    }

    const renderAddedMessages = () => {
        if (addedMessages.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Your messages:</Text>
                    <SpacingView />
                    <View>
                        {addedMessages.map((item, i) => {
                            return (
                                <View key={i}>
                                    <SocialExtensionMessageOrder
                                        message={item}
                                        price={messagePrice}
                                        onRemove={item => removeMessage(item)}
                                    />
                                    <SpacingView />
                                    <SpacingView />
                                </View>
                            )
                        })}
                    </View>
                </View>
            )
        }
    }

    const renderTokens = () => {
        if (extendedTokenIndexes && extendedTokenIndexes.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Choose your sender "the pixels inc" to send a message</Text>
                    <View style={styles.gallery}>
                        {extendedTokenIndexes.map((item) => {
                            return (
                                <StakeTokenButton
                                    onPress={() => chooseExtendedToken(item)}
                                    title={"#" + item}
                                    selected={isExtendedTokenSelected(item)}
                                    provider={provider}
                                    contractAbi={abis.ThePixels}
                                    contractAddress={addressesByNetwork(provider).ThePixels}
                                    tokenId={item}
                                    key={item}
                                />
                            )
                        })}
                    </View>
                </View>
            )
        } else if (extendedTokenIndexes && extendedTokenIndexes.length == 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Sorry, no available "the pixels inc"</Text>
                </View>
            )
        } else {
            return (
                <View />
            )
        }
    }

    const addMessageEnabled = () => {
        if (message && message.length > 0 && isAddMessageEanbled) {
            return true
        }
        return false
    }

    const renderForm = () => {
        if (selectedExtendedTokenIndex) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>{"Send a message"}</Text>
                    <Text style={GlobalStyles.description}>{"Enter the token id of the pixel you would like to send the message to and write your message. To save gas, you can send multiple messages in a single transaction."}</Text>
                    <SpacingView />
                    <View style={styles.tokenIdContainer}>
                        <TextInput
                            maxLength={4}
                            style={styles.tokenIdInput}
                            value={targetTokenIdText}
                            onChangeText={text => onTargetTokenIdChange(text)}
                            placeholder="token id"
                        />
                        {renderTargetToken()}
                    </View>
                    <SpacingView />
                    <TextInput
                        multiline
                        maxLength={140}
                        numberOfLines={4}
                        style={styles.messageInput}
                        onChangeText={text => setMessage(text)}
                        placeholder="message"
                        value={message}
                    />
                    <SpacingView />
                    <CTAButton
                        onPress={() => addMessage()}
                        title={"add message"}
                        disabled={!addMessageEnabled()}
                    />
                    <SpacingView />
                </View>
            )
        } else {
            return (
                <View />
            )
        }
    }

    const renderSendMessage = () => {
        if (isApprovedForAll && addedMessages.length > 0) {
            if (hasEnoughBalance()) {
                const BN = Web3.utils.BN;
                const totalPrice = new BN(messagePrice).mul(new BN(addedMessages.length))
                const finalPrice = Web3.utils.fromWei(totalPrice)
                return (
                    <View>
                        <CTAButton
                            onPress={() => performSendMessage()}
                            title={"send - " + finalPrice + " $INT"}
                            disabled={!isAddMessageEanbled}
                        />
                    </View>
                )
            } else {
                return (
                    <View>
                        <CTAButton
                            title={"not enough $INT balance"}
                            disabled={true}
                        />
                    </View>
                )
            }
        } else if (!isApprovedForAll && addedMessages.length > 0) {
            return (
                <View>
                    <CTAButton
                        onPress={() => performAppprove()}
                        title={"approve"}
                        disabled={!isAddMessageEanbled}
                    />
                </View>
            )
        }
    }

    const removeMessage = (message) => {
        let newMessages = addedMessages.filter(item => {
            return item != message
        })
        setAddedMessages(newMessages)
    }

    const render = () => {
        return (
            <View>
                {renderTokens()}
                <SpacingView />
                <SpacingView />
                <SpacingView />
                {renderForm()}
                <SpacingView />
                <SpacingView />
                <SpacingView />
                {renderAddedMessages()}
                <SpacingView />
                {renderSendMessage()}
                <Text>{warning}</Text>
            </View>
        );
    }

  return (
    <View>
      <ProviderAwareView
        provider={provider}
        render={() => {
          return render()
        }}
        chainId={0}
        includeErrorMessage
      />
    </View>
  );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    gallery: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap"
    },
    description: {
        width: 600,
        fontSize: 16,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro'
    },
    tokenIdContainer: {
        flex: 1,
        flexDirection: "row"
    },
    tokenIdInput: {
        width: 200,
        height: 50,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
        marginRight: 10
    },
    messageInput: {
        width: 400,
        height: 80,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
    },
});

export default SocialExtensionSendMessagePixel;
