import React, { useState, useEffect } from 'react';
import { TextInput, Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import StakeTokenButton from '../../../components/StakeTokenButton';
import SocialExtensionTargetToken from './SocialExtensionTargetToken';
import GlobalStyles from '../../../styles/GlobalStyles';

function SocialExtensionMessageOrder({ provider, message, price, onRemove }) {
    return (
        <View style={styles.container}>
            <View style={styles.messageContainer}>
                <Text style={styles.messageTitle}>{"Message: "}</Text>
                <Text style={GlobalStyles.description}>{message.message}</Text>
                <SpacingView/>
                <SpacingView/>
                <Text>{"Sender Token ID: #" + message.senderTokenId}</Text>
                <Text>{"Recipent Token ID: #" + message.targetTokenId}</Text>
            </View>
            <View style={styles.editContainer}>
                <View style={styles.rightViewContainer}>
                    <Text style={GlobalStyles.description}>{Web3.utils.fromWei(price) + " $INT"}</Text>
                </View>
                <View style={styles.removeButtonContainer}>
                    <CTAButton
                        onPress={() => {
                            if (onRemove) {
                                onRemove(message)
                            }
                        }}
                        title="x"
                        type="x"
                    />
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 20,
        flex: 1,
        flexDirection: "row",
        backgroundColor: "#e8e8e8",
        alignSelf: 'flex-start'
    },
    title: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    tokenIdContainer: {
        flex: 1,
        flexDirection: "row"
    },
    tokenIdInput: {
        width: 200,
        height: 50,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
        marginRight: 10
    },
    messageInput: {
        width: 400,
        height: 80,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
    },
    messageTitle: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    messageContainer: {
        width: 400,
    },
    rightViewContainer: {
        alignSelf: "center",
    },
    removeButtonContainer: {
        alignSelf: "center",
        marginLeft: 10
    },
    editContainer: {
        flex: 1,
        flexDirection: "row",
        alignSelf: "center",
    }
});

export default SocialExtensionMessageOrder;
