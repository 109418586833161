import React, { useState, useEffect } from 'react';
import { Button, FlatList, StyleSheet, Text, View, TextInput } from "react-native";
import { addressesByNetwork, addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import CTAButton from '../../components/CTAButton';
import SpacingView from '../../components/SpacingView';
import ProviderAwareView from '../../components/ProviderAwareView';
import StakeTokenButton from '../../components/StakeTokenButton';
import ExtensionButton from '../../components/ExtensionButton';
import ExtensionOrder from '../../components/ExtensionOrder';
import GlobalStyles from '../../styles/GlobalStyles';

import variantData from '../../assets/extensions/background/variants.json'

async function getINTBalance(provider) {
	if (typeof provider !== 'undefined') {
	  const web3 = new Web3(provider);
  
	  const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);
  
	  const accounts = await web3.eth.getAccounts();
	  const selectedAccount = accounts[0];
  
	  return await Contract.methods.balanceOf(selectedAccount).call()
	}
  }

async function performExtendTokens(provider, orders, onStarted, onError, onFinished) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		const tokenIds = orders.map((order) => { return order.tokenId })
		const variantIds = orders.map((order) => { return order.extension.variantId })
		const useCollectionTokens = orders.map((order) => { return (order.useCollectionToken ? order.useCollectionToken : false) })
		const collectionTokenIds = orders.map((order) => { return (order.collectionTokenId ? order.collectionTokenId : 0) })

		return Contract.methods.extendMultipleWithVariants(selectedAccount, 0, tokenIds, variantIds, useCollectionTokens, collectionTokenIds).send({
			from: selectedAccount,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
			.on('error', (error, receipt) => {
				onError()
			})
			.on('transactionHash', () => {
				onStarted()
			})
			.then((data) => {
				onFinished()
			})
	} else {
		onError("Please connect your wallet first!")
	}
}

async function approveToken(provider, onStarted, onError, onFinished) {
	if (typeof web3 !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.DummyERC721, addressesByNetwork(provider).INT);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];
		const maxAmount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"

		return Contract.methods.approve(addressesByNetwork(provider).ThePixelsIncExtensionStorage, maxAmount).send({
			from: selectedAccount,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
			.on('error', (error, receipt) => {
				console.log(error)
				onError()
			})
			.on('transactionHash', () => {
				onStarted()
			})
			.then((data) => {
				onFinished()
			})
	} else {
		onError("Please connect your wallet first!")
	}
}

async function getIsApprovedForAll(provider) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.DummyERC20, addressesByNetwork(provider).INT);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];
		if (selectedAccount) {
			return Contract.methods.allowance(selectedAccount, addressesByNetwork(provider).ThePixelsIncExtensionStorage).call()
		}
	}
}

async function getExtension(provider, extensionId) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];
		if (selectedAccount) {
			return Contract.methods.extensions(extensionId).call()
		}
	}
}

async function getOrderPrices(provider, orders) {
	if (typeof provider !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		const tokenIds = orders.map((order) => { return order.tokenId })
		const variantIds = orders.map((order) => { return order.extension.variantId })
		const useCollectionTokens = orders.map((order) => { return (order.useCollectionToken ? order.useCollectionToken : false) })
		const collectionTokenIds = orders.map((order) => { return (order.collectionTokenId ? order.collectionTokenId : 0) })

		return Contract.methods.variantDetails(selectedAccount, 0, tokenIds, variantIds, useCollectionTokens, collectionTokenIds).call()
	}
}

async function getTokens(provider) {
	if (typeof provider !== 'undefined') {

		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.tokensOfOwner(selectedAccount).call()
	}
}

async function getCollectionBalanceOf(provider, collectionAddress) {
	if (typeof provider !== 'undefined') {

		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.DummyERC721, collectionAddress)

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return await Contract.methods.balanceOf(selectedAccount).call()
	}
}

async function extendPixel(provider, tokenId, onStarted, onError, onFinished) {
	if (typeof web3 !== 'undefined') {
		const web3 = new Web3(provider);
		const Contract = new web3.eth.Contract(abis.ThePixels, addressesByNetwork(provider).ThePixels);

		const accounts = await web3.eth.getAccounts();
		const selectedAccount = accounts[0];

		return Contract.methods.updateDNAExtension(tokenId).send({
			from: selectedAccount,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
			.on('error', (error, receipt) => {
				onError()
			})
			.on('transactionHash', () => {
				onStarted()
			})
			.then((data) => {
				onFinished()
			})
	} else {
		onError("Please connect your wallet first!")
	}
}

const getAllVariants = () => {
	let variants = [];
	allBackgrounds.forEach((item) => {
		item.backgrounds.forEach((background) => {
			variants.push(background)
		})
	})
	return variants
}

function BackgroundExtension({ provider, useGlobalState }) {
	const [warning, setWarning] = useState(null);
	const [basketWarning, setBasketWarning] = useState(null);
	const [isLive, setIsLive] = useState(null)

	const [tokenIndexes, setTokenIndexes] = useState([])
	const [selectedToken, setSelectedToken] = useState(null);
	const [selectedVariant, setSelectedVariant] = useState(null);

	const [variants, setVariants] = useState([]);
	const [orders, setOrders] = useState([])
	const [ordersWithPrices, setOrdersWithPrices] = useState([])
	const [totalOrderPrice, setTotalOrderPrice] = useState()
	const [walletState, setWalletState] = useGlobalState("walletState")

	const [extendedTokens, setExtendedTokens] = useState(null)
	const [isApprovedForAll, setIsApprovedForAll] = useState(false);
	const [isExtensionDisabled, setIsExtensionDisabled] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState(null);
	const [collectionBalance, setCollectionBalance] = useState(0);
	const [selectedCollectionToken, setSelectedCollectionToken] = useState();
	const [intBalance, setIntBalance] = useState(0);
	
	const hasEnoughBalance = () => {
		const BN = Web3.utils.BN;
		const totalPrice = Web3.utils.toWei(totalOrderPrice, "ether");
		const intBalanceBN = new BN(intBalance)
		const totalPriceBN = new BN(totalPrice)
		
		if (intBalanceBN.gte(totalPriceBN)) {
			return true;
		}
		return false;
	}

	const reset = () => {
		setWarning(null)
		setOrders([])
		setOrdersWithPrices([])
		setSelectedToken(null)
		setSelectedVariant(null)
		refresh()
		setWalletState(walletState + 1)
		setCollectionBalance(0)
		setSelectedCollectionToken(null)
		setBasketWarning(null)
	}

	const refresh = async () => {
		let _extension = await getExtension(provider, 0)
		if (_extension && _extension.isEnabled) {
			setIsLive(_extension.isEnabled);
		}

		let _tokens = await getTokens(provider)
		setIntBalance(await getINTBalance(provider))
		setTokenIndexes(_tokens)
		refreshVariants()
		
		getIsApprovedForAll(provider).then(data => {
			if (data && data > 0) {
				setIsApprovedForAll(true)
			}
		})
	}

	const refreshOrders = async () => {
		if (orders && orders.length > 0) {
			const result = await getOrderPrices(provider, orders)
			if (result && result[1]) {
				let totalPrice
				const BN = Web3.utils.BN;
				result[1].forEach((status, index) => {
					orders[index].visualPrice = status.cost;

					if (!totalPrice) {
						totalPrice = new BN(status.cost)
					} else {
						totalPrice = new BN(totalPrice).add(new BN(status.cost))
					}
				})
				if (totalPrice) {
					setTotalOrderPrice(Web3.utils.fromWei(totalPrice))
				}
				setOrdersWithPrices(orders)
			} else {
				setOrdersWithPrices([])
			}
		}else{
			setOrdersWithPrices([])
		}
	}

	const refreshVariants = async () => {
		if (selectedToken) {
			const allVariants = getAllVariants()
			const orders = allVariants.map((variant) => {
				return { tokenId: selectedToken, extension: variant }
			})
			const result = await getOrderPrices(provider, orders)
			console.log("resulltt", result);
			if (result && result[0] && result[1]) {
				result[0].forEach((variant, index) => {
					orders[index].extension.isDisabled = !variant.isEnabled;
					orders[index].extension.count = variant.count;
				})
				result[1].forEach((status, index) => {
					orders[index].extension.isClaimed = status.isAlreadyClaimed;
					orders[index].extension.contractPrice = Web3.utils.fromWei(status.cost);
					orders[index].extension.supply = status.supply;
				})
				setVariants(variants.map((x) => x))
			} else {
				setVariants(variants.map((x) => x))
			}
		}
	}

	const refreshCollectionBalance = async () => {
		setSelectedCollection(null)
		setCollectionBalance(0);

		if (selectedVariant && selectedVariant.isCollab) {
			const balance = await getCollectionBalanceOf(provider, selectedVariant.collection)
			setSelectedCollection(selectedVariant.collection)
			setCollectionBalance(balance);
		}
	}

	useEffect(() => {
		setVariants(allBackgrounds)
	}, [provider])

	useEffect(() => {
		refresh()
		refreshOrders()
	}, [provider, orders])

	useEffect(() => {
		refreshVariants()
	}, [selectedToken])

	useEffect(() => {
		refreshCollectionBalance()
	}, [selectedVariant])

	const renderTokens = () => {
		if (tokenIndexes && tokenIndexes.length > 0) {
			return (
				<View>
					<Text style={GlobalStyles.title}>Choose your "the pixels inc"</Text>
					<SpacingView />
					<View style={styles.gallery}>
						{tokenIndexes.map((item) => {
							return (
								<StakeTokenButton
									onPress={() => chooseToken(item)}
									title={"#" + item}
									selected={isTokenSelected(item)}
									provider={provider}
									contractAbi={abis.ThePixels}
									contractAddress={addressesByNetwork(provider).ThePixels}
									tokenId={item}
									key={item}
								/>
							)
						})}
					</View>
				</View>
			)
		}else if (tokenIndexes && tokenIndexes.length == 0) {
			return (
				<View>
					<Text style={GlobalStyles.title}>Sorry, no available "the pixels inc"</Text>
				</View>
			)
		}else{
			return (
				<View/>
			)
		}
	}

	const renderCollectionTokenIdInput = () => {

		if (selectedVariant && collectionBalance && collectionBalance > 0) {
			let balanceText;
			if (collectionBalance > 1) {
				balanceText = collectionBalance + " " + selectedVariant.name + " NFTs"
			}else {
				balanceText = collectionBalance + " " + selectedVariant.name + " NFT"
			}
			return (
				<View>
					<Text style={GlobalStyles.title}>{"Collab Collection"}</Text>
					<Text style={GlobalStyles.description}>{"It seems you have " + balanceText + ". Please before adding this order to basket, enter the token id of your NFT to get this collab background for free. You can use your collab collection NFT only once."}</Text>
					<SpacingView />
					<Text style={GlobalStyles.description}>{"If this NFT belongs to you and is not used before, it should cost 0 $INT to claim this background."}</Text>
					<SpacingView />
					<SpacingView />
					<TextInput
						style={styles.textInput}
						onChangeText={setSelectedCollectionToken}
						placeholder="token id"
					/>
					<SpacingView />
					<SpacingView />
				</View>
			)
		}else{
			return (
				<View/>
			)
		}
	}

	const chooseToken = (tokenIndex) => {
		setSelectedVariant(null)
		setSelectedToken(tokenIndex)
		setSelectedCollection(null)
		setSelectedCollectionToken(null)
		setBasketWarning(null)
	}

	const chooseVariant = (variant) => {
		if (selectedToken) {
			setSelectedVariant(variant)
		}
	}

	const isTokenSelected = (index) => {
		return index == selectedToken
	}

	const isVariantSelected = (variant) => {
		return variant == selectedVariant
	}

	const canSelectVariant = (variant) => {
		if (variant.isDisabled) {
			return false;
		}
		if (variant.isClaimed) {
			return true;
		}else {
			let supply = parseInt(variant.supply)
			let count = parseInt(variant.count)
			if (supply > 0 && count == supply) {
				return false;
			}
		}
		return true;
	}

	const addToBasket = () => {
		const order = {}
		order.tokenId = selectedToken
		order.extension = selectedVariant

		if (selectedVariant.isCollab && selectedCollectionToken) {
			order.useCollectionToken = true;
			order.collectionTokenId = selectedCollectionToken
			
			let canAdd = true
			orders.forEach((item) => {
				if (item.useCollectionToken && order.collectionTokenId == item.collectionTokenId) {
					if (item.extension.variantId == order.extension.variantId) {
						canAdd = false
					}
				}
			})

			if (!canAdd) {
				setBasketWarning("You already used this token id for this variant. Please choose different token id")
				return
			}
		}



		let replaced = false;
		const newOrders = orders.map((item) => {
			if (item.tokenId == order.tokenId && item.extension.variantId == order.extension.variantId) {
				replaced = true;
				return order;
			}
			return item;
		})
		if (!replaced) {
			newOrders.push(order)
		}
		setBasketWarning(null)
		setOrders(newOrders)
	}

	const removeFromBasket = (order) => {
		const newOrders = orders.filter((item) => {
			if (item == order) {
				return false
			}
			return true
		})
		setOrders(newOrders)
	}

	const renderBackgrounds = (backgrounds) => {
		if (selectedToken) {
			return (
				<View>
					{backgrounds.map((background) => {
						return (
							<View>
								<Text style={GlobalStyles.title}>{background.name}:</Text>
								<SpacingView />
								<View style={styles.gallery}>
									{background.backgrounds.map((item) => {
										return (
											<ExtensionButton
												onPress={() => chooseVariant(item)}
												variant={item}
												selected={isVariantSelected(item)}
												canSelect={canSelectVariant(item)}
											/>
										)
									})}
								</View>
								<SpacingView />
								<SpacingView />
							</View>
						)
					})}
				</View>
			)
		}
	}

	const renderAddToBasket = () => {
		if (tokenIndexes && tokenIndexes.length > 0) {
			return (
				<View>
					<CTAButton
						onPress={() => addToBasket()}
						title="add to basket"
						type="small"
						disabled={!selectedToken || !selectedVariant}
					/>
					<Text>{basketWarning}</Text>
				</View>
			)
		}
	}

	const renderExecuteExtension = () => {
		if (ordersWithPrices.length > 0) {
			if (!isLive) {
				return (
					<View>
						<CTAButton
							onPress={() => extendTokens()}
							title={totalOrderPrice + " $INT - extension is not live"}
							type="small"
							disabled={!isLive}
						/>
						<Text>{warning}</Text>
					</View>
				)
			}else if (isApprovedForAll) {
				if (hasEnoughBalance()) {
					return (
						<View>
							<CTAButton
								onPress={() => extendTokens()}
								title={totalOrderPrice + " $INT - extend"}
								type="small"
								disabled={isExtensionDisabled}
							/>
							<Text>{warning}</Text>
						</View>
					)
				}else{
					return (
						<View>
							<CTAButton
								onPress={() => extendTokens()}
								title={"not enough $INT to extend"}
								type="small"
								disabled={true}
							/>
							<Text>{warning}</Text>
						</View>
					)
				}
			} else {
				return (
					<View>
						<CTAButton
							onPress={() => performAppprove()}
							title="approve"
							type="small"
						/>
						<Text>{warning}</Text>
					</View>
				)
			}
		}
	}

	const renderOrders = () => {
		if (ordersWithPrices.length > 0) {
			return (
				<View>
					<Text style={GlobalStyles.title}>selected extensions:</Text>
					<SpacingView />
					<View>
						{ordersWithPrices.map((item) => {
							return (
								<View>
									<SpacingView />
									<ExtensionOrder
										onPress={() => chooseVariant(item)}
										onRemove={(order) => {
											removeFromBasket(order)
										}}
										order={item}
										provider={provider}
									/>
								</View>
							)
						})}
					</View>
				</View>
			)
		} else {
			return (<View />)
		}
	}

	const extendTokens = () => {
		setWarning(null)
		performExtendTokens(provider, ordersWithPrices,
			() => {
				setWarning("Extending, hold on!")
				setIsExtensionDisabled(true)
			},
			() => {
				setWarning("Opps, something went wrong!")
				setIsExtensionDisabled(false)
			}
			,
			() => {
				setWarning("You've just extended!")
				setIsExtensionDisabled(false)
				reset()
			})
	}

	const performAppprove = () => {
		setWarning(null)
		approveToken(provider,
			() => {
				setWarning("Approving, hold on!")
			},
			(error) => {
				setWarning("Opps, something went wrong!")
			}
			,
			() => {
				setWarning("You've just approved!")
				refresh()
				refreshOrders()
			})
	}

	return (
		<View>
			<Text style={GlobalStyles.title}>Background Extension</Text>
			<Text style={GlobalStyles.description}>Please choose your "the pixels inc" and then choose your background. You can claim different backgrounds for different pixels to save gas!</Text>
			<SpacingView />
			<SpacingView />
			<SpacingView />
			<ProviderAwareView
				provider={provider}
				render={() => {
					return (
						<View>
							{renderTokens()}
							<SpacingView />
							<SpacingView />
							<SpacingView />
							<SpacingView />
							{renderBackgrounds(variants)}
							{renderCollectionTokenIdInput()}
							{renderAddToBasket()}
							<SpacingView />
							<SpacingView />
							<SpacingView />
							<SpacingView />
							{renderOrders()}
							<SpacingView />
							<SpacingView />
							<SpacingView />
							<SpacingView />
							{renderExecuteExtension()}
						</View>
					)
				}}
				includeErrorMessage
				chainId={0}
			/>
		</View>
	);
}

const styles = StyleSheet.create({
	title: {
		fontSize: 18,
		letterSpacing: 0,
		fontWeight: 600,
		fontFamily: 'Source Code Pro'
	},
	gallery: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		opacitiy: 0.1
	},
	description: {
		fontSize: 16,
		letterSpacing: 0,
		fontFamily: 'Source Code Pro'
	},
	textInput: {
		width: 200,
		height: 50,
		fontSize: 16,
		letterSpacing: 0,
		borderWidth: 5,
		fontFamily: 'Source Code Pro',
		padding: 10,
	},
});

function categoriesVariants() {
	let collabBackgrounds = variantData.filter((variant) => {
		return variant.category == "collab"
	})

	let contestWinners = variantData.filter((variant) => {
		return variant.category == "contestWinner"
	})

	let communityBackgrounds = variantData.filter((variant) => {
		return variant.category == "community"
	})

	let result = []

	if (collabBackgrounds.length > 0) {
		result.push({
			description: "",
			name: "Collab Backrounds",
			backgrounds: collabBackgrounds
		})
	}

	result.push({
		description: "",
		name: "Contest Winners",
		backgrounds: contestWinners
	})

	result.push({
		description: "",
		name: "Community Backrounds",
		backgrounds: communityBackgrounds
	})

	return result;
}

const allBackgrounds = categoriesVariants()

export default BackgroundExtension;
