import React, { useState, useEffect } from 'react';
import { Button, Image, StyleSheet, Text, TextInput, View } from "react-native";
import { addresses, abis } from "@project/contracts";
import Web3 from 'web3';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import NavigationButton from '../../components/NavigationButton';
import SpacingView from '../../components/SpacingView';
import RewardsAbout from './RewardsAbout';
import RewardsAllClaims from './RewardsAllClaims';
import StakingAll from './StakingAll';
import UnstakingAll from './UnstakingAll';

function Rewards({provider, useGlobalState}) {
  return (
    <View style={styles.content} >
      <View style={[styles.container, {
            flexDirection: "row",
            flexWrap: "wrap"
          }]}>
            <NavigationButton to="/$int/about" title="about $INT" />
            <NavigationButton to="/$int/claim" title="claim rewards" />
            <NavigationButton to="/$int/stake" title="stake" />
            <NavigationButton to="/$int/unstake" title="unstake" />
          </View>
      <SpacingView/>
      <SpacingView/>
      <Switch>
            <Route path="/$int/about"><RewardsAbout provider={provider}/></Route>
            <Route path="/$int/claim"><RewardsAllClaims provider={provider} useGlobalState={useGlobalState}/></Route>
            <Route path="/$int/stake"><StakingAll provider={provider}/></Route>
            <Route path="/$int/unstake"><UnstakingAll provider={provider}/></Route>
            <Route path="/$int/"><RewardsAbout provider={provider}/></Route>
          </Switch>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 1000
  },
  bar: {
    flexDirection: "row",
    flexWrap: "wrap"
  }
});

export default Rewards;
