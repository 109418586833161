import React, { useState, useEffect } from 'react';
import { TextInput, Button, FlatList, StyleSheet, Text, View } from "react-native";
import { addresses, abis, addressesByNetwork } from "@project/contracts";
import Web3 from 'web3';

import ProviderAwareView from '../../../components/ProviderAwareView';
import CTAButton from '../../../components/CTAButton';
import SpacingView from '../../../components/SpacingView';
import StakeTokenButton from '../../../components/StakeTokenButton';
import SocialExtensionTargetToken from './SocialExtensionTargetToken';
import GlobalStyles from '../../../styles/GlobalStyles';
import SocialExtensionMessage from './SocialExtensionMessage'

const extensionId = 2


async function updateMessageVisibility(provider, senderTokenId, messages, onStarted, onError, onFinished) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        const tokenIds = messages.map(v => senderTokenId)
        const messageIds = messages.map(v => v.id)
        const visibilities = messages.map(v => v.isHidden)

        return Contract.methods.updateMessageVisibility(tokenIds, messageIds, visibilities).send({
            from: selectedAccount,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
        })
            .on('error', (error, receipt) => {
                onError()
            })
            .on('transactionHash', () => {
                onStarted()
            })
            .then((data) => {
                onFinished()
            })
    } else {
        onError("Please connect your wallet first!")
    }
}

async function getTokens(provider) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.CoreRewarder, addressesByNetwork(provider).ThePixelsIncRewarder)

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.tokensOfOwner(selectedAccount).call()
    }
}

async function getVariantIdsOfTokens(provider, tokenIds) {
    if (typeof provider !== 'undefined') {

        const web3 = new Web3(provider);
        const Contract = new web3.eth.Contract(abis.ThePixelsIncExtensionStorage, addressesByNetwork(provider).ThePixelsIncExtensionStorage);

        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];

        return await Contract.methods.currentVariantIdsOf(2, tokenIds).call()
    }
}

async function getMessagePrice(provider) {
    if (typeof provider !== 'undefined') {
        const web3 = new Web3(provider);

        const Contract = new web3.eth.Contract(abis.ThePixelsIncSocialMessageExtension, addressesByNetwork(provider).ThePixelsIncSocialMessageExtension);

        return await Contract.methods.messageTypes(0).call()
    }
}

function SocialExtensionSendMessage({ provider, useGlobalState }) {
    const [allMessages, setAllMessages] = useState();
    const [originalMessages, setOriginalMessages] = useState();
    const [extendedTokenIndexes, setExtendedTokenIndexes] = useState();
    const [selectedExtendedTokenIndex, setSelectedExtendedTokenIndex] = useState();
    const [walletState, setWalletState] = useGlobalState("walletState")
    const [hasDifference, setHasDiffecence] = useState(false)
    const [changedMessages, setChangedMessages] = useState()
    const [warning, setWarning] = useState(null);

    useEffect(() => {
        refresh(true)
    }, [provider])

    useEffect(() => {
        reset()
    }, [selectedExtendedTokenIndex])

    const reset = () => {
        setAllMessages()
        setChangedMessages()
        getAllMessages()
    }

    const getAllMessages = () => {
        if (selectedExtendedTokenIndex) {
            fetch('https://api.int.art/collections/the-pixels-inc/extensions/messages/' + selectedExtendedTokenIndex + "?includeHidden=true")
                .then(response => response.json())
                .then(data => {
                    setAllMessages(data.messages)
                    setOriginalMessages(JSON.parse(JSON.stringify(data.messages)))
                })
        }
    }

    const onMessageChange = () => {
        let differentMessages = []
        originalMessages.forEach((originalMessage, i) => {
            if (originalMessage.isHidden != allMessages[i].isHidden) {
                differentMessages.push(allMessages[i])
            }
        })
        setWarning()
        setChangedMessages(differentMessages)
    }

    const refresh = async () => {
        if (!provider) {
            return
        }

        let _tokens = await getTokens(provider)

        let _extendableTokens = []
        let _extendedTokens = []
        let _variantIds = await getVariantIdsOfTokens(provider, _tokens)

        _variantIds.forEach((variant, index) => {
            if (variant != "0") {
                _extendedTokens.push(_tokens[index])
            } else {
                _extendableTokens.push(_tokens[index])
            }
        })

        setExtendedTokenIndexes(_extendedTokens)
        setWalletState(walletState + 1)
    }

    const chooseExtendedToken = (token) => {
        setWarning()
        setSelectedExtendedTokenIndex(token)
    }

    const isExtendedTokenSelected = (index) => {
        return index == selectedExtendedTokenIndex
    }

    const renderTokens = () => {
        if (extendedTokenIndexes && extendedTokenIndexes.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Choose your "the pixels inc" to see its messages</Text>
                    <View style={styles.gallery}>
                        {extendedTokenIndexes.map((item) => {
                            return (
                                <StakeTokenButton
                                    onPress={() => chooseExtendedToken(item)}
                                    title={"#" + item}
                                    selected={isExtendedTokenSelected(item)}
                                    provider={provider}
                                    contractAbi={abis.ThePixels}
                                    contractAddress={addressesByNetwork(provider).ThePixels}
                                    tokenId={item}
                                    key={item}
                                />
                            )
                        })}
                    </View>
                </View>
            )
        } else if (extendedTokenIndexes && extendedTokenIndexes.length == 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Sorry, no available "the pixels inc"</Text>
                </View>
            )
        } else {
            return (
                <View />
            )
        }
    }

    const renderMessages = () => {
        if (allMessages && allMessages.length > 0) {
            return (
                <View>
                    <Text style={GlobalStyles.title}>Inbox:</Text>
                    <SpacingView />
                    <View>
                        {allMessages.map((item, i) => {
                            return (
                                <View key={i}>
                                    <SocialExtensionMessage
                                        message={item}
                                        onChange={() => onMessageChange()}
                                    />
                                    <SpacingView />
                                    <SpacingView />
                                </View>
                            )
                        })}
                    </View>
                </View>
            )
        } else if (allMessages) {
            return (
                <Text style={GlobalStyles.title}>This pixel has no messages :(</Text>
            )
        }
    }

    const performUpdateMessageVisibility = () => {
        updateMessageVisibility(provider, selectedExtendedTokenIndex, changedMessages,
            () => {
                setWarning("Saving, hold on!")
            },
            () => {
                setWarning("Opps, something went wrong!")
            }
            ,
            () => {
                setWarning("You've just saved!")
                reset()
            })
    }

    const renderSaveButton = () => {
        if (changedMessages && changedMessages.length > 0) {
            return (
                <View>
                    <CTAButton
                        onPress={() => performUpdateMessageVisibility()}
                        title={"save"}
                    />
                </View>
            )
        }
    }

    const render = () => {
        return (
            <View>
                {renderTokens()}
                <SpacingView />
                <SpacingView />
                <SpacingView />
                {renderMessages()}
                {renderSaveButton()}
                <Text>{warning}</Text>
            </View>
        );
    }

    return (
        <View>
            <ProviderAwareView
                provider={provider}
                render={() => {
                    return render()
                }}
                chainId={0}
                includeErrorMessage
            />
        </View>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 600,
        fontFamily: 'Source Code Pro'
    },
    gallery: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap"
    },
    description: {
        width: 600,
        fontSize: 16,
        letterSpacing: 0,
        fontFamily: 'Source Code Pro'
    },
    tokenIdContainer: {
        flex: 1,
        flexDirection: "row"
    },
    tokenIdInput: {
        width: 200,
        height: 50,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
        marginRight: 10
    },
    messageInput: {
        width: 400,
        height: 80,
        fontSize: 16,
        letterSpacing: 0,
        borderWidth: 5,
        fontFamily: 'Source Code Pro',
        padding: 10,
    },
});

export default SocialExtensionSendMessage;
